import React, { useEffect, useState } from 'react'
import {
  IconButton,
  Typography,
  Card,
  CardContent,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LambdaFetch from '../functions/FetchFromLambda'
import { makeStyles } from '@material-ui/core/styles'
import SimpleAutoComplete from '../functions/SimpleAutoComplete'
import { AntTabs } from '../components/VendorTabs'
import { VendorInvoices } from '../components/VendorInvoices'
import VendorLocations from '../components/VendorLocations'
import LoadingCircle from '../components/common/LoadingCircle'
import VendorPos from '../components/VendorPos'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'
import { smallScreenWidth } from '../constants/AppConstants'
import ServerSideAutoCompletev2 from '../functions/ServerSideAutoCompletev2'
import LoadingButton from '../components/LoadingButton'
import {dateFormat} from '../utils/formatter';
import {VendorInvoicesServerSide} from '../components/VendorInvoicesServerSide'
import {VendorPosServerSide} from '../components/VendorPosServerSide'
import AutocompleteDropDown from '../components/AutocompleteDropdown'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem'
  },
  paper: {
    padding: theme.spacing(2),
    color: 'rgba(0,0,0,0.9)',
    height: '100%'
  },
  descript: { fontSize: '0.8rem', color: '#545454' },
  descriptVal: { fontSize: '0.85rem' }
}))

export default function PoVendorInvoicePayment (props) {
  const classes = useStyles()
  const { credentials } = props.fetchInitialData
  const [state, setstate] = useState({
    groups: [],
    selectedVendor: '',
    vendorData: null,
    invoices: [],
    locations: [],
    pos: [],
    fetched: false,
    isLoading: false
  })
  const [vendor, setVendor] = React.useState('')
  const [selectedGroup, setSelectedGroup] = useState('')
  const [tabVal, setTabVal] = useState(0)

  useEffect(() => {
    async function fetch () {
      try {
        const resp = await LambdaFetch(
          'vendors',
          'post',
          credentials.user.accessToken,
          JSON.stringify({
            action: 'get-vendorgroups'
          }),
          '',
          credentials
        )
        setstate({ ...state, groups: resp.data.groups, fetched: true })
      } catch (e) {
        console.log(e)
      }
    }

    fetch()
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()
    getVendor(vendor)
  }

  const getVendor = async id => {
    try {
      setstate({ ...state, isLoading: true })
      if (!id) return null
      const resp = await LambdaFetch(
        'vendors',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-po-vendor',
          vendorGroup: selectedGroup,
          vendor: id
        }),
        '',
        credentials
      )
      setstate({
        ...state,
        vendorData: resp.data.vendor[0],
        invoices: resp.data.invoices,
        locations: resp.data.locations,
        pos: resp.data.pos,
        isLoading: false
      })
    } catch (e) {
      console.log(e)
    }
  }

  const printValue = (dataObj, lookup, description, secondary, secondType) => {
    const isDate = description.toLowerCase().indexOf('date') !== -1

    return (
      <div>
        <span className={classes.descript}>{`${description}:  `}</span>
        <span className={classes.descriptVal}>
          {`${
            isDate
              ? dateFormat(dataObj[lookup])
              : dataObj[lookup]
          }${
            secondary && dataObj[secondary]
              ? ` (${
                  secondType !== 'date'
                    ? dataObj[secondary]
                    : dateFormat(dataObj[secondary])
                })`
              : ''
          } `}
        </span>
      </div>
    )
  }
  const { vendorData } = state
  if (!state.fetched) {
    return <LoadingCircle />
  }
  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size='small'
        onClick={() => props.history.push('/')}
        aria-label='delete'
      >
        <ArrowBackIcon />
        {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
          'Dashboard'}
      </IconButton>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
        PO Vendor-Invoice-Payment
      </Typography>

      <div className={classes.root}>
        <Paper elevation={0} style={{ padding: '1rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid
            
              container
              alignContent='center'
              alignItems='flex-start'
              spacing={2}
            >
              <Grid item>

              <AutocompleteDropDown
                  {...props}
                  id={'group'}
                  name={'group'}
                  width='200px'
                  key={'group'}
                  required={true}
                  variant='standard'
                  label={'Vendor Group'}
                  value={selectedGroup}
                  category={'vendor-group'}
                  screen={'po-vendor'}
                  onChange={val => setSelectedGroup(val ? val.key_value : null)}
                />
              </Grid>

              <Grid item>
                <ServerSideAutoCompletev2
                  disabled={false}
                  required={true}
                  id='vendor'
                  width='320px'
                  label='Vendor'
                  accessToken={credentials.user.accessToken}
                  error='No Vendor found'
                  variant='standard'
                  handleChange={(id, hit) => {
                    setVendor(id ? id : null)
                  }}
                  credentials={credentials}
                  apiResource='vendors'
                  secondaryParams={{
                    limit: 20,
                    group: selectedGroup,
                    action: 'vendor-autocomplete'
                  }}
                />
              </Grid>
            </Grid>
            <div style={{ marginTop: '1rem' }}>
              <LoadingButton
                label='Submit'
                isLoading={state.isLoading}
                color='primaryVLButton'
                buttonType='submit'
              />
            </div>
          </form>
        </Paper>

        {!!state.vendorData && (
          <Card style={{ marginTop: '1rem' }}>
            <CardContent style={{ position: 'relative' }}>
              <div
                style={{
                  display: 'flex',
                  marginTop: '0.5rem',
                  justifyContent: 'flex-start'
                }}
              >
                <div>
                  <Typography variant='h5'>
                    {state.vendorData.VENDOR_VNAME}
                  </Typography>
                  <Typography variant='subtitle1'>{`Vendor Group: ${state.vendorData.VENDOR_GROUP}`}</Typography>
                </div>
              </div>

              <AntTabs
                value={tabVal}
                color={credentials.primaryAppColor}
                handleChange={(event, newValue) => {
                  setTabVal(newValue)
                }}
                tabs={['Main', 'Invoices', 'Locations', 'PO']}
              />

              <div style={{ marginTop: '1rem' }}>
                {tabVal === 0 && (
                  <Grid
                    container
                    spacing={1}
                    direction='row'
                    justify='flex-start'
                    alignItems='stretch'
                  >
                    <Grid item xs={12} sm={6} md={6}>
                      <Paper elevation={3} className={classes.paper}>
                        <Typography variant='h5'>Main</Typography>
                        <Grid
                          container
                          spacing={0}
                          direction='row'
                          justify='space-evenly'
                          alignItems='flex-start'
                        >
                          <Grid item xs={12}>
                            {printValue(
                              vendorData,
                              'VEN_CLASS',
                              'Vendor Class'
                            )}
                            {printValue(
                              vendorData,
                              'PROC_GRP',
                              'Process Group'
                            )}
                            {printValue(vendorData, 'TAX_ID', 'Tax ID')}
                            {printValue(vendorData, 'PAY_VENDOR', 'Pay Vendor')}
                            {printValue(
                              vendorData,
                              'VENDOR_STATUS',
                              'Vendor Status'
                            )}
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Paper elevation={3} className={classes.paper}>
                        <Typography variant='h5'>
                          Contact Information
                        </Typography>
                        <Grid
                          container
                          spacing={0}
                          direction='row'
                          justify='space-evenly'
                          alignItems='flex-start'
                        >
                          <Grid item xs={12}>
                            {printValue(
                              vendorData,
                              'VENDOR_CONTCT',
                              'Contact Name'
                            )}
                            {printValue(vendorData, 'PHONE_NUM', 'Telephone')}
                            {printValue(
                              vendorData,
                              'MOBILE_NUM',
                              'Mobile Phone'
                            )}
                            {printValue(vendorData, 'FAX_NUM', 'Fax')}
                            {printValue(vendorData, 'E_MAIL_ADDRESS', 'E-mail')}
                            {printValue(vendorData, 'URL_ADDR', 'URL')}
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Paper elevation={3} className={classes.paper}>
                        <Typography variant='h6'>
                          Address Information
                        </Typography>

                        <Grid
                          container
                          spacing={0}
                          direction='row'
                          justify='space-evenly'
                          alignItems='flex-start'
                        >
                          <Grid item xs={12} sm={6}>
                            {printValue(vendorData, 'ADDR1', 'Address 1')}
                            {printValue(vendorData, 'ADDR2', 'Address 2')}
                            {printValue(vendorData, 'ADDR3', 'Address 3')}
                            {printValue(vendorData, 'ADDR4', 'Address 4')}
                            {printValue(
                              vendorData,
                              'CITY_ADDR5',
                              'City or Address 5'
                            )}
                            {printValue(
                              vendorData,
                              'STATE_PROV',
                              'State or Province'
                            )}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            {printValue(
                              vendorData,
                              'POSTAL_CODE',
                              'Postal Code'
                            )}
                            {printValue(vendorData, 'COUNTY', 'County')}
                            {printValue(vendorData, 'COUNTRY', 'Country')}
                            {printValue(vendorData, 'REGION', 'Region')}
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                )}

                {tabVal === 1 && (

                  <VendorInvoicesServerSide
                    key={`${state.vendorData.VENDOR}_${state.vendorData.VENDOR_GROUP}`}
                    {...props}
                    vendor={state.vendorData.VENDOR}
                    selectedGroup={state.vendorData.VENDOR_GROUP}
                    accessToken={credentials.user.accessToken}
                    credentials={credentials}
                    fileName={`Vendor_Invoices_${state.vendorData.VENDOR_VNAME.replaceAll(' ','_')}`}
                  />
                )}
                {tabVal === 2 && <VendorLocations data={state.locations}
                fileName={`Vendor_Locations_${state.vendorData.VENDOR_VNAME.replaceAll(' ','_')}`} />}
                {tabVal === 3 && (
                  <VendorPosServerSide
                  key={`${state.vendorData.VENDOR}_${state.vendorData.VENDOR_GROUP}`}
                    {...props}
                    vendor={state.vendorData.VENDOR}
                    selectedGroup={state.vendorData.VENDOR_GROUP}
                    accessToken={credentials.user.accessToken}
                    credentials={credentials}
                    fileName={`Vendor_Invoices_${state.vendorData.VENDOR_VNAME.replaceAll(' ','_')}`}
                  />
                )
                }
              </div>
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  )
}
