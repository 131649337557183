import React, { useState } from 'react'
import {
  IconButton,
  Slide,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import TableMui from './TableMui'
import getTableOptions from '../constants/TableOptions'
import { poCols,poWithVendorCols } from '../constants/VendorCols'
import PoDetail from './PoDetail'
import LambdaFetch from '../functions/FetchFromLambda'
import { makeStyles } from '@material-ui/core/styles'
import { smallScreenWidth } from '../constants/AppConstants'
import PoDetailDialog from './PoDetailDialog'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: props => props.primaryColor,
    height: '60px'
  }
}))

export default function VendorPos (props) {
  const { credentials } = props.fetchInitialData
  const classes = useStyles({ primaryColor: credentials.primaryAppColor })

  const [state, setstate] = useState({
    open: false,
    poHeaderData: null,
    poDetailData: null,
    fetched: false
  })

  const getPo = async (id, comp, code) => {

    try {
      if (!id || !comp) return null
      setstate({...state, open: true})

      const resp = await LambdaFetch(
        'po',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-po',
          company: comp,
          po: id,
          code: code
        }),
        '',
        credentials
      )

      setstate({
        ...state,
        poHeaderData: resp.data.poHeader[0],
        poDetailData: resp.data.poDetail,
        open: true
      })
    } catch (e) {
      console.log(e)
    }
  }

  const options = getTableOptions({ cols: props.showVendor ? poWithVendorCols : poCols, fileName: props.fileName })

  const { poHeaderData, poDetailData, open } = state
  const pos = props.data.map(p => {
    return {
      ...p,
      view: (
        <div className='editLink' onClick={() => getPo(p.PO_NUMBER, p.COMPANY, p.PO_CODE)}>
          view
        </div>
      )
    }
  })

  return (
    <>
      <PoDetailDialog
        {...props}
        open={open}
        poHeaderData={poHeaderData}
        poDetailData={poDetailData}
        close={() =>
          setstate({ ...state, poHeaderData: null, poDetailData: null, open: false })
        }
      />

      <TableMui cols={props.showVendor ? poWithVendorCols : poCols} data={pos} options={options} title='' />
    </>
  )
}
