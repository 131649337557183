import React from 'react';
import TableMui from './TableMui';
import moment from 'moment-timezone';
import getTableOptions from '../constants/TableOptions';
import { payRateHistCols } from '../constants/EmployeeCols';
import {dateFormat} from '../utils/formatter';

export default function PayRateHistory(props) {
  if (!props.data) return null;

  const options = getTableOptions({cols: payRateHistCols, fileName: props.fileName});

  const tableData = props.data.map((row) => {
    return [
      dateFormat(new Date(row.BEG_DATE)),
      row.PAY_RATE,
      row.ANNUAL_SALARY,
      dateFormat(new Date(row.DATE_STAMP)),
      row.SALARY_CLASS,
      row.POS_LEVEL,
      row.ANNUAL_HOURS,
      row.NBR_FTE,
    ];
  });
  return (
    <TableMui
      cols={payRateHistCols}
      data={tableData}
      options={options}
      title=""
    />
  );
}
