import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment-timezone";
import NumberFormat from "react-number-format";
import {
  Grid,
  Paper,
  Typography,
  Slide,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MouseOverPopover from "../functions/Popover";
import {dateFormat} from '../utils/formatter';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: props => props.primaryColor
  },
  paper: {
    padding: theme.spacing(1),
  },
  descript: { fontSize: "1rem", fontWeight: "500" },
  descriptVal: { fontSize: "1rem", fontWeight: "bold" },

  TableHeaderDescriptionRow: { backgroundColor: props => props.primaryColor },
  TableHeaderDescriptionText: { color: "white", fontWeight: "bold" },

  bold: { fontWeight: "bold" },
  subHead: { fontWeight: "bold", backgroundColor: "rgba(0,0,0,0.09)" },
  subHeader: { marginBottom: "0.5rem" },
  tableSubtot: {
    fontWeight: "bold",
  },
  tableGrand: {
    borderTop: "solid lightgrey 2px",
    borderBottom: 0,
    "&:last-child th, &:last-child td": {
      borderBottom: 0,
    },
  },
}));

export default function PayStubDetails(props) {

  const classes = useStyles({primaryColor: props.fetchInitialData.credentials.primaryAppColor});

  const printValue = (description, value) => {
    return (
      <div>
        <span className={classes.descript}>{`${description}:  `}</span>
        <span className={classes.descript}>{value}</span>
      </div>
    );
  };

  const printNum = (val) => (
    <NumberFormat
      value={(Math.round(val * 100) / 100).toFixed(2)}
      thousandSeparator={true}
      prefix={"$"}
      displayType="text"
    />
  );

  const createTableHeaders = (headers) => {
    return (
      <TableHead>
        <TableRow className={classes.TableHeaderDescriptionRow}>
          {headers.map((h) => (
            <TableCell
              key={h.key}
              className={classes.TableHeaderDescriptionText}
              align={h.align}
            >
              {h.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const subHead = (label, width) => {
    return (
      <TableRow>
        <TableCell className={classes.subHead} colSpan={width}>
          {label}
        </TableCell>
      </TableRow>
    );
  };
  const subTotal = (array, width) => {
    return (
      <TableRow>
        <TableCell
          className={classes.tableSubtot}
          align="left"
          colSpan={width - 1}
        >
          Subtotal:
        </TableCell>
        <TableCell className={classes.tableSubtot} align="right" colSpan={1}>
          {printNum(
            array.reduce((tot, r) => {
              tot = parseFloat(r.DED_AMT) + tot;
              return tot;
            }, 0)
          )}
        </TableCell>
      </TableRow>
    );
  };

  const grandTotal = (array, width) => {
    return (
      <TableRow className={classes.tableGrand}>
        <TableCell
          className={classes.tableSubtot}
          align="left"
          colSpan={width - 1}
        >
          Grand Total:
        </TableCell>
        <TableCell className={classes.tableSubtot} align="right" colSpan={1}>
          {printNum(array.reduce((tot, r) => (r.DED_AMT += tot), 0))}
        </TableCell>
      </TableRow>
    );
  };
 const checkDetails = Object.assign({},props.checkDetails);

  if(checkDetails.paystubDed.length > 0 ) {
    checkDetails.paystubDed = checkDetails.paystubDed.map(c => Object.assign({},c))
  }

  if(checkDetails.paystubDist.length > 0 ) {
    checkDetails.paystubDist = checkDetails.paystubDist.map(c => Object.assign({},c))
  }

  if(checkDetails.paystubWage.length > 0 ) {
    checkDetails.paystubWage = checkDetails.paystubWage.map(c => Object.assign({},c))
  }

  const ded_emp = checkDetails.paystubDed.filter(
    (ded) => ded.TAX_FLAG !== "T" && ded.PAID_BY === "EMPLOYEE"
  );
  const ded_comp = checkDetails.paystubDed.filter(
    (ded) => ded.TAX_FLAG !== "T" && ded.PAID_BY === "COMPANY"
  );
  const tax_emp = checkDetails.paystubDed.filter(
    (ded) => ded.TAX_FLAG === "T" && ded.PAID_BY === "EMPLOYEE"
  );
  const tax_comp = checkDetails.paystubDed.filter(
    (ded) => ded.TAX_FLAG === "T" && ded.PAID_BY === "COMPANY"
  );
  const all_ded = checkDetails.paystubDed.filter(
    (ded) =>
      ded.TAX_FLAG !== "T" &&
      (ded.PAID_BY === "EMPLOYEE" || ded.PAID_BY === "COMPANY")
  );
  const all_tax = checkDetails.paystubDed.filter(
    (ded) =>
      ded.TAX_FLAG === "T" &&
      (ded.PAID_BY === "EMPLOYEE" || ded.PAID_BY === "COMPANY")
  );
  const tableElevation = 3;
  return (
    <Dialog
      open={true}
      fullScreen
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => props.handleClose()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <div style={{ maxWidth: "1300px", margin: "auto", paddingTop: "1rem" }}>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="flex-start"
            alignItems="stretch"
          >
            <Grid item xs={12}>
              <Paper elevation={tableElevation}>
                <div
                  style={{
                    margin: "auto",
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div>
                    <Typography style={{ margin: "0.5rem" }} variant="body1">
                      {printValue(
                        "Date",
                        dateFormat(checkDetails.main.CHECK_DATE)
                      )}
                    </Typography>
                    <Typography style={{ margin: "0.5rem" }} variant="body1">
                      {printValue("Type", checkDetails.main.M_CHECK_TYPE)}
                    </Typography>
                  </div>
                  <div>
                    <Typography style={{ margin: "0.5rem" }} variant="body1">
                      {printValue(
                        "Gross",
                        printNum(checkDetails.main.GROSS_PAY)
                      )}
                    </Typography>

                    <Typography style={{ margin: "0.5rem" }} variant="body1">
                      {printValue(
                        "Net",
                        printNum(checkDetails.main.NET_PAY_AMT)
                      )}
                    </Typography>
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Paper
                elevation={0}
                className={classes.paper}
                style={{ widht: "100%" }}
              >
                <Typography className={classes.subHeader} variant="h5">
                  Earnings
                </Typography>

                <TableContainer
                  className={classes.TableContainer}
                  elevation={tableElevation}
                  component={Paper}
                >
                  <Table
                    className={classes.Table}
                    size="small"
                    aria-label="a dense table"
                  >
                    {createTableHeaders([
                      { id: "earn_desc", label: "Description", align: "left" },
                      { id: "earn_hour", label: "Hours", align: "right" },
                      { id: "earn_rate", label: "Rate", align: "right" },
                      { id: "earn_amt", label: "Wage Amount", align: "right" },
                    ])}
                    <TableBody>
                      {checkDetails.paystubWage.map((r, index) => {
                        return (
                          <TableRow
                            key={`${index}_${r.CHECK_DESC}_${r.HOURS}_${r.RATE}`}
                          >
                            <TableCell align="left">{r.CHECK_DESC}</TableCell>
                            <TableCell align="right">{r.HOURS}</TableCell>
                            <TableCell align="right">{r.RATE}</TableCell>
                            <TableCell align="right">
                              {printNum(r.WAGE_AMOUNT)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      <TableRow className={classes.tableGrand}>
                        <TableCell
                          className={classes.bold}
                          align="left"
                          colSpan={1}
                        >
                          Totals
                        </TableCell>
                        <TableCell
                          className={classes.bold}
                          align="right"
                          colSpan={1}
                        >
                          {Math.round(
                            checkDetails.paystubWage.reduce(
                              (tot, r) => (r.HOURS += tot),
                              0
                            ) * 100
                          ) / 100}
                        </TableCell>
                        <TableCell
                          className={classes.bold}
                          align="right"
                          colSpan={2}
                        >
                          {printNum(
                            checkDetails.paystubWage.reduce(
                              (tot, r) => (r.WAGE_AMOUNT += tot),
                              0
                            )
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>

              <Paper
                elevation={0}
                className={classes.paper}
                style={{ widht: "100%", marginTop: "8px" }}
              >
                <Typography className={classes.subHeader} variant="h5">
                  Tax
                </Typography>

                <TableContainer
                  className={classes.TableContainer}
                  elevation={tableElevation}
                  component={Paper}
                >
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    {createTableHeaders([
                      { id: "tax_desc", label: "Description", align: "left" },
                      { id: "tax_amt", label: "Amount", align: "right" },
                    ])}
                    <TableBody>
                      {subHead("Employee Paid", 2)}
                      {tax_emp.map((r, index) => {
                        return (
                          <TableRow
                            key={`${index}_${r.CHECK_DESC}_${r.DESCRIPTION}_${r.DED_AMT}`}
                          >
                            <TableCell align="left">
                              <MouseOverPopover
                                label={r.CHECK_DESC}
                                description={r.DESCRIPTION}
                              />
                            </TableCell>
                            <TableCell align="right">
                              {printNum(parseFloat(r.DED_AMT))}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {subTotal(tax_emp, 2)}
                      {subHead("Company Paid", 2)}
                      {tax_comp.map((r, index) => {
                        return (
                          <TableRow key={`${index}_${r.DED_AMT}`}>
                            <TableCell align="left">
                              <MouseOverPopover
                                label={r.CHECK_DESC}
                                description={r.DESCRIPTION}
                              />
                            </TableCell>
                            <TableCell align="right">
                              {printNum(r.DED_AMT)}
                            </TableCell>
                          </TableRow>
                        );
                      })}

                      {subTotal(tax_comp, 2)}
                      {grandTotal(all_tax, 2)}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Paper
                elevation={0}
                className={classes.paper}
                style={{ widht: "100%", minHeight: "100%" }}
              >
                <Typography className={classes.subHeader} variant="h5">
                  Deductions
                </Typography>

                <TableContainer
                  className={classes.TableContainer}
                  elevation={tableElevation}
                  component={Paper}
                >
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    {createTableHeaders([
                      { id: "ded_desc", label: "Description", align: "left" },
                      { id: "ded_calc", label: "Calc Type", align: "left" },
                      { id: "ded_amt", label: "Amount", align: "right" },
                    ])}
                    <TableBody>
                      {subHead("Employee Paid", 3)}
                      {ded_emp.map((r, index) => {
                        return (
                          <TableRow key={`${index}_${r.DED_AMT}`}>
                            <TableCell align="left">
                              <MouseOverPopover
                                label={r.CHECK_DESC}
                                description={r.DESCRIPTION}
                              />
                            </TableCell>
                            <TableCell align="left">{r.TAX_STATUS}</TableCell>
                            <TableCell align="right">
                              {printNum(r.DED_AMT)}
                            </TableCell>
                          </TableRow>
                        );
                      })}

                      {subTotal(ded_emp, 3)}

                      {subHead("Company Paid", 3)}
                      {ded_comp.map((r, index) => {
                        return (
                          <TableRow key={`${index}_${r.DED_AMT}`}>
                            <TableCell align="left">
                              <MouseOverPopover
                                label={r.CHECK_DESC}
                                description={r.DESCRIPTION}
                              />
                            </TableCell>
                            <TableCell align="left">{r.TAX_STATUS}</TableCell>
                            <TableCell align="right">
                              {printNum(r.DED_AMT)}
                            </TableCell>
                          </TableRow>
                        );
                      })}

                      {subTotal(ded_comp, 3)}

                      {grandTotal(all_ded, 3)}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Paper
                elevation={0}
                className={classes.paper}
                style={{ widht: "100%" }}
              >
                <Typography className={classes.subHeader} variant="h5">
                  Distributions
                </Typography>

                <TableContainer
                  className={classes.TableContainer}
                  elevation={tableElevation}
                  component={Paper}
                >
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    {createTableHeaders([
                      { id: "dist_bank", label: "Bank", align: "left" },
                      { id: "dist_acct", label: "Account", align: "left" },
                      { id: "dist_type", label: "Type", align: "left" },
                      { id: "dist_status", label: "Status", align: "left" },
                      { id: "dist_amt", label: "Amount", align: "right" },
                    ])}
                    <TableBody>
                      {checkDetails.paystubDist.map((r) => {
                        return (
                          <TableRow key={r.EBNK_ACCT_NBR}>
                            <TableCell component="th" scope="row">
                              {r.DESCRIPTION}
                            </TableCell>
                            <TableCell align="left">
                              {r.EBNK_ACCT_NBR}
                            </TableCell>
                            <TableCell align="left">{r.Account}</TableCell>
                            <TableCell align="left">{r.STATUS}</TableCell>
                            <TableCell align="right">
                              {printNum(r.DIST_AMOUNT)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
}
