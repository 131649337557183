import React, { useEffect, useState } from 'react'
import {
  IconButton,
  Typography,
  Grid,
  Paper,
  TextField,
  InputAdornment
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LambdaFetch from '../functions/FetchFromLambda'
import { makeStyles } from '@material-ui/core/styles'
import SimpleAutoComplete from '../functions/SimpleAutoComplete'
import LoadingCircle from '../components/common/LoadingCircle'
import PoDetail from '../components/PoDetail'
import { smallScreenWidth } from '../constants/AppConstants'
import LoadingButton from '../components/LoadingButton'
import ServerSideAutoCompletev2 from '../functions/ServerSideAutoCompletev2'
import AutocompleteDropDown from '../components/AutocompleteDropdown'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem'
  }
}))

export default function Po (props) {
  const classes = useStyles()
  const { credentials } = props.fetchInitialData
  const [state, setstate] = useState({
    companies: [],
    poNums: [],
    poHeaderData: null,
    poDetailData: null,
    poReceive: null,
    poReleases: null,
    fetched: true,
    isLoading: false
  })
  const [headers, setHeaders] = React.useState({
    company: null,
    poNum: null,
    code: null
  })
  const [showHeaders, setShowHeaders] = React.useState(true)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const company = urlParams.get('company')
    const po = urlParams.get('po')
    const poCode = urlParams.get('pocode')
    if (company && po) {
      getPo(po, company, poCode)
      setHeaders({company: company, poNum: po, code: poCode})
      setShowHeaders(false)
    } else {
      // async function fetch () {
      //   try {
      //     const resp = await LambdaFetch(
      //       'po',
      //       'post',
      //       credentials.user.accessToken,
      //       JSON.stringify({
      //         action: 'get-options'
      //       }),
      //       '',
      //       credentials
      //     )
      //     setstate({ ...state, companies: resp.data.company, fetched: true })
      //   } catch (e) {
      //     console.log(e)
      //   }
      // }

      // fetch()
    }
  }, [])

  const handleSubmit = e => {
    e.preventDefault()
    if (!headers.poNum || !headers.company) return null
    getPo(headers.poNum, headers.company, headers.code)
  }

  const getPo = async (id, company, code, release) => {
    try {
      setstate({ ...state, isLoading: true })
      setHeaders({ ...headers, poNum: id })
      if (!id) return null
      const resp = await LambdaFetch(
        'po',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-po',
          company: company ? company : headers.company,
          po: id,
          code: code,
          release: release
        }),
        '',
        credentials
      )
      setstate({
        ...state,
        poHeaderData: resp.data.poHeader[0],
        poDetailData: resp.data.poDetail,
        poReceive: resp.data.poReceive,
        poReleases: resp.data.poReleases,
        isLoading: false
      })
    } catch (e) {
      console.log(e)
    }
  }
  const updateRelease = release => {
    getPo(headers.poNum, headers.company, headers.code, release)
  }
  const handleChange = async (name, value) => {
    if (name === 'company') {
      // getPoOptions(value)
      setHeaders({ ...headers, company: value })
    } else if (name === 'poNum') {
      setHeaders({ ...headers, poNum: value })
    } else if (name === 'code') {
      setHeaders({ ...headers, code: value })
    }
  }

  const getPoOptions = async comp => {
    if (!comp) {
      setHeaders({ ...headers, poNum: null, company: comp })
      setstate({ ...state, poNums: [] })
      return null
    }
    setHeaders({ ...headers, company: comp })
    const resp = await LambdaFetch(
      'po',
      'post',
      credentials.user.accessToken,
      JSON.stringify({
        action: 'get-po-options',
        company: comp
      }),
      '',
      credentials
    )
    setstate({
      ...state,
      poNums: resp.data.pos
    })
  }
  const { poHeaderData, poDetailData, poReceive, poReleases } = state

  if (!state.fetched) {
    return <LoadingCircle />
  }
  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size='small'
        onClick={() => props.history.push('/')}
        aria-label='delete'
      >
        <ArrowBackIcon />
        {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
          'Dashboard'}
      </IconButton>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
        PO
      </Typography>

      <div className={classes.root}>
      {showHeaders && (
        <Paper elevation={0} style={{ padding: '1rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              alignContent='center'
              alignItems='flex-start'
              spacing={2}
            >
              <Grid item>
              <AutocompleteDropDown
                  {...props}
                  id={'company'}
                  name={'Company'}
                  width='175px'
                  key={'company'}
                  required={true}
                  variant='standard'
                  label={'Company'}
                  value={headers.company}
                  category={'company'}
                  screen={'po'}
                  onChange={val =>
                    handleChange('company', val ? val.key_value : '')
                  }
                />
              </Grid>
              <Grid item>
                <ServerSideAutoCompletev2
                  disabled={false}
                  required={true}
                  id='poNum'
                  width='320px'
                  label='PO Number'
                  accessToken={credentials.user.accessToken}
                  error='No PO found'
                  variant='standard'
                  handleChange={(id, hit) => {
                    handleChange('poNum', id)
                  }}
                  credentials={credentials}
                  apiResource='po'
                  secondaryParams={{
                    action: 'autocomplete-po',
                    limit: 10,
                    company: headers.company
                  }}
                  dontOpenOnFocus
                />
              </Grid>

              <Grid item style={{ marginTop: '5px' }}>
                <TextField
                  id='poCode'
                  label='PO Code'
                  style={{ width: '125px' }}
                  value={headers.code}
                  onChange={e => handleChange('code', e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <>
                        <InputAdornment position='start'>
                          <div></div>
                        </InputAdornment>
                      </>
                    )
                  }}
                />
              </Grid>
            </Grid>
            <div style={{ marginTop: '1rem' }}>
              <LoadingButton
                label='Submit'
                isLoading={state.isLoading}
                color='primaryVLButton'
                buttonType='submit'
              />
            </div>
          </form>
        </Paper>
      )}
        {!!state.poHeaderData && (
          <PoDetail
            key={JSON.stringify(poHeaderData)}
            {...props}
            poHeaderData={poHeaderData}
            poDetailData={poDetailData}
            poReceive={poReceive}
            poReleases={poReleases}
            updateRelease={updateRelease}
          />
        )}
      </div>
    </div>
  )
}
