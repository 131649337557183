import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import Reports from './Reports'
import PrintManager from './PrintManager'
import StaticFiles from './StaticFiles'
import { AntTabs, AntTab } from '../components/common/Tabs'

const tabs = {
  0: 'reports',
  1: 'print_manager',
  2: 'static_files'
}
const tabValues = {
  reports: 0,
  print_manager: 1,
  static_files: 2
}

const ReportsModule = ({ ...props }) => {
  const [tabVal, setTabVal] = useState(0)
  useEffect(() => {
    viewHash()

    const getHash = () => {}
    window.addEventListener('hashchange', getHash)
  }, [])

  const viewHash = () => {
    setTabVal(
      window.location.hash ? tabValues[window.location.hash.slice(1)] : 0
    )
  }

  const handleTabChange = (event, newValue) => {
    setTabVal(newValue)

    window.location.hash = tabs[newValue]
  }
  const staticFileAuth =
    !!props.fetchInitialData.credentials.user.pagePermission['static-files']
  return (
    <div style={{ padding: '0 0.5rem' }}>
      <Grid item xs={12} sm={12} md={12}>
        <AntTabs
          color={props.fetchInitialData.credentials.primaryAppColor}
          value={tabVal}
          onChange={handleTabChange}
          variant='scrollable'
          scrollButtons='auto'
          aria-label='ant example'
        >
          <AntTab
            color={props.fetchInitialData.credentials.primaryAppColor}
            label='Reports'
            value={0}
          />

          <AntTab
            color={props.fetchInitialData.credentials.primaryAppColor}
            label='Print Manager'
            value={1}
          />
          {staticFileAuth && (
            <AntTab
              color={props.fetchInitialData.credentials.primaryAppColor}
              label='Static Files'
              value={2}
            />
          )}
        </AntTabs>
        <div style={{ position: 'relative', marginTop: '1.5rem' }}>
          {tabVal === 0 && (
            <Reports
              history={props.history}
              fetchInitialData={props.fetchInitialData}
            />
          )}
          {tabVal === 1 && (
            <PrintManager
              history={props.history}
              fetchInitialData={props.fetchInitialData}
            />
          )}
          {tabVal === 2 && staticFileAuth && <StaticFiles {...props} />}
        </div>
      </Grid>
    </div>
  )
}

export default ReportsModule