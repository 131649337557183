import React from 'react'
import TableFooter from '@material-ui/core/TableFooter'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import MuiTablePagination from '@material-ui/core/TablePagination'
import { withStyles } from '@material-ui/core/styles'

import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Table, Button } from '@material-ui/core'
import TableBody from '@material-ui/core/TableBody'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'

const defaultFooterStyles = {}

class CustomFooter extends React.Component {
  handleRowChange = event => {
    this.props.changeRowsPerPage(event.target.value)
  }

  handlePageChange = page => {
    this.props.changePage(page - 1)
  }

  render () {
    const { count, classes, textLabels, rowsPerPage, page } = this.props

    const footerStyle = {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '0px 24px 0px 24px'
    }
    return (
      // <TableFooter>
      //   <TableRow>
      //     <TableCell style={footerStyle} colSpan={1800}>
            <MuiTablePagination
              component='div'
              count={count}
              rowsPerPage={parseInt(rowsPerPage)}
              page={page}
              labelRowsPerPage={textLabels.rowsPerPage}
              labelDisplayedRows={({ from, to, count }) => {
                if (this.props.noPagination) {

                  const showOfMany = this.props.tableData.length < rowsPerPage ?'' :  'of many'
                  return `${page * rowsPerPage + 1}-${page * rowsPerPage +
                    this.props.tableData.length} ${showOfMany}`
                }
                return `${from}-${to} ${textLabels.displayRows} ${count}`
              }}
              backIconButtonProps={{
                'aria-label': textLabels.previous
              }}
              nextIconButtonProps={{
                'aria-label': textLabels.next
              }}
              //rowsPerPageOptions={[rowsPerPage]}
              onPageChange={this.handlePageChange}
              // onChangePage={this.handlePageChange}
              onRowsPerPageChange={this.handleRowChange}
              ActionsComponent={
                this.props.noPagination
                  ? props => (
                      <TablePaginationActionsNoPagnination
                        {...props}
                        tableData={this.props.tableData}
                      />
                    )
                  : TablePaginationActions
              }
            />
      //     </TableCell>
      //   </TableRow>
      // </TableFooter>
    )
  }
}

const TablePaginationActions = props => {
  const classes = useStyles1()
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  let pages = [-1, 0, 1, 2, 3]
  let paginationPages = pages
    .map(page => {
      return props.page + page
    })
    .filter(page => page >= 1 && page < count / rowsPerPage + 1)

  return (
    <div className={classes.root}>
      {page > 4 && (
        <>
          <IconButton
            style={{ borderRadius: '5px' }}
            onClick={() => onPageChange(1)}
            disabled={page === 1}
            aria-label='first page'
          >
            <span style={{ fontSize: '14px' }}>{1}</span>
          </IconButton>
          <span style={{ fontSize: '14px', margin: '0.25rem' }}>...</span>
        </>
      )}

      {paginationPages.map(p => {
        return (
          <IconButton
            key={p}
            style={{ borderRadius: '5px' }}
            disabled={p - 1 === page}
            size='small'
            onClick={() => onPageChange(p)}
            aria-label='previous page'
          >
            <span style={{ margin: '0.25rem', fontSize: '14px' }}>{p}</span>
          </IconButton>
        )
      })}
      {page < Math.ceil(count / rowsPerPage) - 3 && (
        <>
          <span style={{ fontSize: '14px', margin: '0.25rem' }}>...</span>

          <IconButton
            style={{ borderRadius: '5px' }}
            onClick={() => onPageChange(Math.ceil(count / rowsPerPage))}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label='last page'
          >
            <span style={{ fontSize: '14px' }}>
              {Math.ceil(count / rowsPerPage)}
            </span>
          </IconButton>
        </>
      )}
    </div>
  )
}

const TablePaginationActionsNoPagnination = props => {
  const classes = useStyles1()
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange, tableData } = props
  return (
    <div className={classes.root}>
      <IconButton
        style={{ borderRadius: '5px' }}
        onClick={() => onPageChange(1)}
        disabled={page === 0}
        aria-label='first page'
      >
        <KeyboardArrowLeft />
      </IconButton>

      <IconButton
        style={{ borderRadius: '5px' }}
        onClick={() => onPageChange(page + 2)}
        disabled={tableData.length < rowsPerPage}
        aria-label='last page'
      >
        <KeyboardArrowRight />
      </IconButton>
    </div>
  )
}

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(3.5)
  }
}))

export default withStyles(defaultFooterStyles, { name: 'CustomFooter' })(
  CustomFooter
)
