// import React, { useState, useEffect } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
// import {
//   IconButton,
//   Typography,
//   Grid,
//   MenuItem,
//   TextField,
//   Switch,
//   Card,
//   CardContent,
//   FormGroup,
//   InputAdornment,
//   Button,
//   Dialog,
//   DialogContent,
//   DialogActions,
//   DialogTitle,
//   Slide,
//   CircularProgress,
//   FormControlLabel,
//   Checkbox
// } from '@material-ui/core'
// import LambdaFetch from '../functions/FetchFromLambda'
// import CloseIcon from '@material-ui/icons/Close'
// import TableMui from '../components/TableMui'
// import SimpleAutoComplete from '../functions/SimpleAutoComplete'
// import { AntTabs, AntTab, MyBadge } from '../components/common/Tabs'
// import LoadingButton from '../components/LoadingButton'
// import getTableOptions from '../constants/TableOptions'

// export default function Admin (props) {
//   const { credentials } = props.fetchInitialData

//   const [state, setstate] = useState({
//     roleData: [],
//     selectedRole: null,
//     selectedRoleActions: [],
//     clientValues: []
//   })
//   const [tabVal, setTabVal] = useState(0)
//   const [selectedRole, setSelectedRole] = useState({
//     role: null,
//     actions: []
//   })

//   useEffect(() => {
//     async function fetchMyAPI () {
//       fetchData()
//     }
//     fetchMyAPI()
//   }, [])

//   const fetchData = async () => {
//     try {
//       const resp = await LambdaFetch(
//         'admin',
//         'post',
//         credentials.user.accessToken,
//         JSON.stringify({
//           action: 'get-role-actions'
//         }),
//         '',
//         credentials
//       )
//       setstate({
//         ...state,
//         roleData: resp.data.roleData,
//         clientValues: resp.data.clientValues
//       })
//     } catch (e) {
//       console.log(e)
//     }
//   }
//   const handleSave = async type => {
//     setstate({ ...state, isSaving: true })

//     const updates = state.selectedRoleActions.reduce(
//       (acc, cur) => {
//         if (cur.type === type) {
//           if (cur.active === 1) {
//             acc.active.push(cur.id)
//           } else {
//             acc.inactive.push(cur.id)
//           }
//         }
//         return acc
//       },
//       { active: [], inactive: [] }
//     )

//     const resp = await LambdaFetch(
//       'admin',
//       'post',
//       credentials.user.accessToken,
//       JSON.stringify({
//         action: 'update-actions',
//         ...updates
//       }),
//       'Successfully saved changes',
//       credentials
//     )

//     setstate({ ...state, isSaving: false })
//   }
//   const handleChange = id => {
//     const updatedActions = state.selectedRoleActions.slice().map(a => {
//       if (a.id === id) a.active = a.active === 0 ? 1 : 0
//       return a
//     })
//     setstate({ ...state, selectedRoleActions: updatedActions })
//   }

//   const toggleAll = type => {
//     const count = state.selectedRoleActions.reduce((tot, cur) => {
//       if (cur.type === type) {
//         tot += parseInt(cur.active)
//       }
//       return tot
//     }, 0)
//     const updatedActions = state.selectedRoleActions.slice().map(a => {
//       if (a.type === type) {
//         a.active = count !== 0 ? 0 : 1
//       }
//       return a
//     })

//     setstate({ ...state, selectedRoleActions: updatedActions })
//   }

//   const processComps = state.selectedRoleActions
//     .filter(a => a.type === 'process-level')
//     .reduce((acc, cur) => {
//       if (acc[cur.filter1]) {
//         acc[cur.filter1].push(cur)
//       } else {
//         acc[cur.filter1] = [cur]
//       }
//       return acc
//     }, {})

//   return (
//     <div>
//       <AntTabs
//         color={props.fetchInitialData.credentials.primaryAppColor}
//         value={tabVal}
//         onChange={(event, newValue) => {
//           setTabVal(newValue)
//         }}
//         variant='scrollable'
//         scrollButtons='auto'
//         aria-label='ant example'
//       >
//         <AntTab
//           color={props.fetchInitialData.credentials.primaryAppColor}
//           label='Client Values'
//           value={0}
//         />
//         <AntTab
//           color={props.fetchInitialData.credentials.primaryAppColor}
//           label='Role Management'
//           value={1}
//         />

        
//       </AntTabs>

//       {tabVal === 1 && (
//         <div style={{ margin: '1rem' }}>
//           <SimpleAutoComplete
//             id='selectedRole'
//             name='role'
//             width='300px'
//             variant='standard'
//             label='Role'
//             value={state.selectedRole}
//             onChange={val =>
//               setstate({
//                 ...state,
//                 selectedRole: val,
//                 selectedRoleActions: state.roleData
//                   .map(row => Object.assign({}, row))
//                   .filter(r => r.role === val)
//               })
//             }
//             options={[...new Set(state.roleData.map(r => r.role))]}
//             getOptionLabel={val => `${val}`}
//           />
//           {!!state.selectedRole && (
//             <>
//               {/* <Card style={{ width: '100%', margin: '1rem auto' }}>
//                 <CardContent>
//                   <form
//                     onSubmit={e => {
//                       e.preventDefault()
//                       handleSave('company')
//                     }}
//                   >
//                     <Typography variant='h6' style={{ marginBottom: '0.5rem' }}>
//                       Company
//                       <Button
//                         variant='outlined'
//                         color='primary'
//                         style={{ float: 'right' }}
//                         onClick={() => toggleAll('company')}
//                       >
//                         Toggle All
//                       </Button>
//                     </Typography>
//                     <Grid
//                       container
//                       direction='row'
//                       alignItems='stretch'
//                       spacing={1}
//                     >
//                       {state.selectedRoleActions
//                         .filter(a => a.type === 'company')
//                         .map(a => {
//                           return (
//                             <Grid key={a.id} item xs={12} sm={6} lg={4}>
//                               <FormGroup>
//                                 <FormControlLabel
//                                   style={{ margin: 0 }}
//                                   control={
//                                     <Checkbox
//                                       color='primary'
//                                       size='small'
//                                       checked={a.active === 1 ? true : false}
//                                       onChange={() => handleChange(a.id)}
//                                     />
//                                   }
//                                   label={`${a.value} - ${a.label}`}
//                                 />
//                               </FormGroup>
//                             </Grid>
//                           )
//                         })}
//                     </Grid>
//                     <div style={{ marginTop: '1rem' }}>
//                       <LoadingButton
//                         label='Save'
//                         isLoading={state.isSaving}
//                         color='primaryVLButton'
//                         buttonType='submit'
//                       />
//                     </div>
//                   </form>
//                 </CardContent>
//               </Card> */}

//               <Card style={{ width: '100%', margin: '1rem auto' }}>
//                 <CardContent>
//                   <form
//                     onSubmit={e => {
//                       e.preventDefault()
//                       handleSave('page')
//                     }}
//                   >
//                     <Typography variant='h6' style={{ marginBottom: '0.5rem' }}>
//                       Pages
//                       <Button
//                         variant='outlined'
//                         color='primary'
//                         style={{ float: 'right' }}
//                         onClick={() => toggleAll('page')}
//                       >
//                         Toggle All
//                       </Button>
//                     </Typography>
//                     <Grid
//                       container
//                       direction='row'
//                       alignItems='stretch'
//                       spacing={1}
//                     >
//                       {state.selectedRoleActions
//                         .filter(a => a.type === 'page')
//                         .map(a => {
//                           return (
//                             <Grid key={a.id} item xs={12} sm={6} lg={4}>
//                               <FormGroup>
//                                 <FormControlLabel
//                                   style={{ margin: 0 }}
//                                   control={
//                                     <Checkbox
//                                       color='primary'
//                                       size='small'
//                                       checked={a.active === 1 ? true : false}
//                                       onChange={() => handleChange(a.id)}
//                                     />
//                                   }
//                                   label={`${a.filter1}`}
//                                 />
//                               </FormGroup>
//                             </Grid>
//                           )
//                         })}
//                     </Grid>
//                     <div style={{ marginTop: '1rem' }}>
//                       <LoadingButton
//                         label='Save'
//                         isLoading={state.isSaving}
//                         color='primaryVLButton'
//                         buttonType='submit'
//                       />
//                     </div>
//                   </form>
//                 </CardContent>
//               </Card>

//               <Card style={{ width: '100%', margin: '1rem auto' }}>
//                 <CardContent>
//                   <form
//                     onSubmit={e => {
//                       e.preventDefault()
//                       handleSave('process-level')
//                     }}
//                   >
//                     <Typography variant='h6' style={{ marginBottom: '0.5rem' }}>
//                       Process Level
//                       <Button
//                         variant='outlined'
//                         color='primary'
//                         style={{ float: 'right' }}
//                         onClick={() => toggleAll('process-level')}
//                       >
//                         Toggle All
//                       </Button>
//                     </Typography>

//                     {Object.keys(processComps).map(key => {
//                       return (
//                         <div style={{ margin: '0.5rem 0' }}>
//                           <Typography variant='h6'>{`Company ${key}`}</Typography>
//                           <Grid
//                             container
//                             direction='row'
//                             alignItems='stretch'
//                             spacing={1}
//                           >
//                             {processComps[key].map(a => {
//                               return (
//                                 <Grid key={a.id} item xs={12} sm={6} lg={4}>
//                                   <FormGroup>
//                                     <FormControlLabel
//                                       style={{ margin: 0 }}
//                                       control={
//                                         <Checkbox
//                                           color='primary'
//                                           size='small'
//                                           checked={
//                                             a.active === 1 ? true : false
//                                           }
//                                           onChange={() => handleChange(a.id)}
//                                         />
//                                       }
//                                       label={`${a.process_level_description} (${a.filter2})`}
//                                     />
//                                   </FormGroup>
//                                 </Grid>
//                               )
//                             })}
//                           </Grid>
//                         </div>
//                       )
//                     })}
//                     {/* {state.selectedRoleActions
//                       .filter(a => a.type === 'process-level')
//                       .map(a => {
//                         return (
//                           <Grid key={a.id} item xs={12} sm={6} lg={4}>
//                             <FormGroup>
//                               <FormControlLabel
//                                 style={{ margin: 0 }}
//                                 control={
//                                   <Checkbox
//                                     color='primary'
//                                     size='small'
//                                     checked={a.active === 1 ? true : false}
//                                     onChange={() => handleChange(a.id)}
//                                   />
//                                 }
//                                 label={<div>
//                                   <Typography variant="p">{`Company: ${a.secondary_value}`}</Typography>
//                                   <Typography variant="subtitle2">{`${a.process_level_description} (${a.value})`}</Typography>
//                                 </div>
//                                 }
//                               />
//                             </FormGroup>
//                           </Grid>
//                         )
//                       })} */}
//                     <div style={{ marginTop: '1rem' }}>
//                       <LoadingButton
//                         label='Save'
//                         isLoading={state.isSaving}
//                         color='primaryVLButton'
//                         buttonType='submit'
//                       />
//                     </div>
//                   </form>
//                 </CardContent>
//               </Card>
//             </>
//           )}
//         </div>
//       )}

//       {tabVal === 0 && <ClientValues {...props} />}
//     </div>
//   )
// }

// function ClientValues (props) {
//   const { credentials } = props.fetchInitialData

//   const [state, setstate] = useState({
//     clientValues: [],
//     isLoading: false
//   })
//   const [currentValue, setCurrentValue] = useState(null)
//   const [newEntry, setNewEntry] = useState(false)
//   useEffect(() => {
//     async function fetchMyAPI () {
//       fetchData()
//     }
//     fetchMyAPI()
//   }, [])

//   const fetchData = async () => {
//     try {
//       const resp = await LambdaFetch(
//         'admin',
//         'post',
//         credentials.user.accessToken,
//         JSON.stringify({
//           action: 'get-client-values'
//         }),
//         '',
//         credentials
//       )
//       setstate({
//         ...state,
//         clientValues: resp.data.clientValues,
//         isLoading: false
//       })

//       setCurrentValue(null)
//       setNewEntry(false)
//     } catch (e) {
//       console.log(e)
//     }
//   }
//   const handleSubmit = async e => {
//     e.preventDefault()
//     const curVal = {
//       ...currentValue,
//       R_KEY: e.target.R_KEY.value,
//       R_VALUE: e.target.R_VALUE.value,
//       R_DESCRIPTION: e.target.R_DESCRIPTION.value,
//       R_ACTIVE: e.target.R_ACTIVE.checked
//     }
//     setstate({ ...state, isLoading: true })

//     await LambdaFetch(
//       'admin',
//       'post',
//       credentials.user.accessToken,
//       JSON.stringify({
//         action: 'update-client-values',
//         clientValue: curVal
//       }),
//       '',
//       credentials
//     )

//     await fetchData()
//   }
//   const handleNewSubmit = async e => {
//     e.preventDefault()
//     setstate({ ...state, isLoading: true })
//     const newVal = {
//       R_KEY: e.target.R_KEY.value,
//       R_VALUE: e.target.R_VALUE.value,
//       R_DESCRIPTION: e.target.R_DESCRIPTION.value,
//       R_ACTIVE: e.target.R_ACTIVE.checked
//     }
//     await LambdaFetch(
//       'admin',
//       'post',
//       credentials.user.accessToken,
//       JSON.stringify({
//         action: 'create-client-values',
//         clientValue: newVal
//       }),
//       '',
//       credentials
//     )

//     await fetchData()
//   }
//   const confirmDelete = async (info) => {
//     const deleteIndeices = info.data.reduce((acc,cur) => {
//       return [...acc,cur.dataIndex]
//     },[])

//     const deleteValues = state.clientValues.slice().filter((row,index) => {
//       return deleteIndeices.includes(index)
//     }).map(row => row.OBJ_ID)

//     await LambdaFetch(
//       'admin',
//       'post',
//       credentials.user.accessToken,
//       JSON.stringify({
//         action: 'delete-client-values',
//         deleteIds: deleteValues
//       }),
//       'Successfully deleted value(s)',
//       credentials
//     )
//       await fetchData()
//   }
//   const tableData = state.clientValues.map(row => {
//     return {
//       ...row,
//       edit: (
//         <div
//           className='editLink'
//           onClick={() => {
//             setCurrentValue(row)
//           }}
//         >
//           edit
//         </div>
//       )
//     }
//   })

//   const options = getTableOptions({
//     cols: clientValueCols,
//     fileName: `client_values`
//   })

//   options['selectableRows'] = 'multiple'
//   options['onRowsDelete'] = confirmDelete
//   options['responsive'] = 'scroll'

//   return (
//     <div style={{ margin: '1rem' }}>
//       {!!currentValue && (
//         <Dialog open={!!currentValue}>
//           <DialogTitle id='alert-dialog-title'>Edit</DialogTitle>
//           <form onSubmit={handleSubmit}>
//             <DialogContent>
//               <Grid
//                 container
//                 direction='row'
//                 alignItems='flex-start'
//                 spacing={2}
//               >
//                 <Grid item>
//                   <TextField
//                     id='R_KEY'
//                     label='Key'
//                     defaultValue={currentValue.R_KEY}
//                     autoComplete='off'
//                   />
//                 </Grid>
//                 <Grid item>
//                   <TextField
//                     id='R_VALUE'
//                     label='Value'
//                     defaultValue={currentValue.R_VALUE}
//                     autoComplete='off'
//                   />
//                 </Grid>
//                 <Grid item>
//                   <FormControlLabel
//                     control={
//                       <Checkbox
//                         id='R_ACTIVE'
//                         defaultChecked={!!currentValue.R_ACTIVE}
//                         name='active'
//                         color='primary'
//                       />
//                     }
//                     label='Active'
//                   />
//                 </Grid>
//                 <Grid item xs={12}>
//                   <TextField
//                     id='R_DESCRIPTION'
//                     label='Description'
//                     defaultValue={currentValue.R_DESCRIPTION}
//                     fullWidth
//                     autoComplete='off'
//                   />
//                 </Grid>
//               </Grid>
//             </DialogContent>
//             <DialogActions>
//               <Button onClick={() => setCurrentValue(null)}>Cancel</Button>

//               <LoadingButton
//                 label='Edit'
//                 isLoading={state.isLoading}
//                 color='primaryVLButton'
//                 buttonType='submit'
//               />
//             </DialogActions>
//           </form>
//         </Dialog>
//       )}

//       {!!newEntry && (
//         <Dialog open={!!newEntry}>
//           <DialogTitle id='alert-dialog-title'>Create Value</DialogTitle>
//           <form onSubmit={handleNewSubmit}>
//             <DialogContent>
//               <Grid
//                 container
//                 direction='row'
//                 alignItems='flex-start'
//                 spacing={2}
//               >
//                 <Grid item>
//                   <TextField id='R_KEY' label='Key' autoComplete='off' />
//                 </Grid>
//                 <Grid item>
//                   <TextField id='R_VALUE' label='Value' autoComplete='off' />
//                 </Grid>
//                 <Grid item>
//                   <FormControlLabel
//                     control={
//                       <Checkbox
//                         id='R_ACTIVE'
//                         name='active'
//                         color='primary'
//                         defaultChecked
//                       />
//                     }
//                     label='Active'
//                   />
//                 </Grid>
//                 <Grid item xs={12}>
//                   <TextField
//                     id='R_DESCRIPTION'
//                     label='Description'
//                     fullWidth
//                     autoComplete='off'
//                   />
//                 </Grid>
//               </Grid>
//             </DialogContent>
//             <DialogActions>
//               <Button onClick={() => setNewEntry(false)}>Cancel</Button>

//               <LoadingButton
//                 label='Create'
//                 isLoading={state.isLoading}
//                 color='primaryVLButton'
//                 buttonType='submit'
//               />
//             </DialogActions>
//           </form>
//         </Dialog>
//       )}
//       <Button
//         style={{
//           margin: '1rem 0',
//           left: 0,
//           position: 'relative'
//         }}
//         color='primary'
//         variant='contained'
//         onClick={() => setNewEntry(true)}
//       >
//         Add Value
//       </Button>

//       <TableMui
//         cols={clientValueCols}
//         data={tableData}
//         options={options}
//         title='Client Values'
//       />
//     </div>
//   )
// }

// const clientValueCols = [
//   {
//     name: 'R_KEY',
//     label: 'Key',
//     options: {
//       filter: true,
//       sort: true
//     }
//   },
//   {
//     name: 'R_VALUE',
//     label: 'Value',
//     options: {
//       filter: true,
//       sort: true
//     }
//   },

//   {
//     name: 'R_DESCRIPTION',
//     label: 'Desc',
//     options: {
//       filter: true,
//       sort: true
//     }
//   },
//   {
//     name: 'R_ACTIVE',
//     label: 'Active',
//     options: {
//       filter: true,
//       sort: true
//     }
//   },
//   {
//     name: 'edit',
//     label: 'edit',
//     options: {
//       download: false,
//       filter: false,
//       sort: false
//     }
//   }
// ]


import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Typography,
  Grid,
  MenuItem,
  TextField,
  Switch,
  Card,
  CardContent,
  FormGroup,
  InputAdornment,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Slide,
  CircularProgress,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import LambdaFetch from '../functions/FetchFromLambda'
import CloseIcon from '@material-ui/icons/Close'
import TableMui from '../components/TableMui'
import SimpleAutoComplete from '../functions/SimpleAutoComplete'
import { AntTabs, AntTab, MyBadge } from '../components/common/Tabs'
import LoadingButton from '../components/LoadingButton'
import getTableOptions from '../constants/TableOptions'
import Users from '../components/Users'
import ReactTable from '../components/ReactTable'

export default function Admin (props) {
  const { credentials } = props.fetchInitialData

  const [state, setstate] = useState({
    roleData: [],
    selectedRole: null,
    selectedRoleActions: [],
    clientValues: []
  })
  const [tabVal, setTabVal] = useState(0)
  const [selectedRole, setSelectedRole] = useState({
    role: null,
    actions: []
  })

  useEffect(() => {
    async function fetchMyAPI () {
      fetchData()
    }
    fetchMyAPI()
  }, [])

  const fetchData = async () => {
    try {
      const resp = await LambdaFetch(
        'admin',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-role-actions'
        }),
        '',
        credentials
      )
      setstate({
        ...state,
        roleData: resp.data.roleData,
        clientValues: resp.data.clientValues
      })
    } catch (e) {
      console.log(e)
    }
  }
  const handleSave = async type => {
    setstate({ ...state, isSaving: true })

    const updates = state.selectedRoleActions.reduce(
      (acc, cur) => {
        if (cur.type === type) {
          if (cur.active === 1) {
            acc.active.push(cur.id)
          } else {
            acc.inactive.push(cur.id)
          }
        }
        return acc
      },
      { active: [], inactive: [] }
    )

    const resp = await LambdaFetch(
      'admin',
      'post',
      credentials.user.accessToken,
      JSON.stringify({
        action: 'update-actions',
        ...updates
      }),
      'Successfully saved changes',
      credentials
    )

    setstate({ ...state, isSaving: false })
  }
  const handleChange = id => {
    const updatedActions = state.selectedRoleActions.slice().map(a => {
      if (a.id === id) a.active = a.active === 0 ? 1 : 0
      return a
    })
    setstate({ ...state, selectedRoleActions: updatedActions })
  }

  const toggleAll = type => {
    const count = state.selectedRoleActions.reduce((tot, cur) => {
      if (cur.type === type) {
        tot += parseInt(cur.active)
      }
      return tot
    }, 0)
    const updatedActions = state.selectedRoleActions.slice().map(a => {
      if (a.type === type) {
        a.active = count !== 0 ? 0 : 1
      }
      return a
    })

    setstate({ ...state, selectedRoleActions: updatedActions })
  }

  const processComps = state.selectedRoleActions
    .filter(a => a.type === 'process-level')
    .reduce((acc, cur) => {
      if (acc[cur.filter1]) {
        acc[cur.filter1].push(cur)
      } else {
        acc[cur.filter1] = [cur]
      }
      return acc
    }, {})

  return (
    <div>
      <AntTabs
        color={props.fetchInitialData.credentials.primaryAppColor}
        value={tabVal}
        onChange={(event, newValue) => {
          setTabVal(newValue)
        }}
        variant='scrollable'
        scrollButtons='auto'
        aria-label='ant example'
      >
        <AntTab
          color={props.fetchInitialData.credentials.primaryAppColor}
          label='Client Values'
          value={0}
        />
        {/* <AntTab
          color={props.fetchInitialData.credentials.primaryAppColor}
          label='Role Management'
          value={1}
        /> */}
        <AntTab
          color={props.fetchInitialData.credentials.primaryAppColor}
          label='User/Role Management'
          value={2}
        />
      </AntTabs>

      {/* {tabVal === 1 && (
        <div style={{ margin: '1rem' }}>
          <SimpleAutoComplete
            id='selectedRole'
            name='role'
            width='300px'
            variant='standard'
            label='Role'
            value={state.selectedRole}
            onChange={val =>
              setstate({
                ...state,
                selectedRole: val,
                selectedRoleActions: state.roleData
                  .map(row => Object.assign({}, row))
                  .filter(r => r.role === val)
              })
            }
            options={[...new Set(state.roleData.map(r => r.role))]}
            getOptionLabel={val => `${val}`}
          />
          {!!state.selectedRole && (
            <>
              <Card style={{ width: '100%', margin: '1rem auto' }}>
                <CardContent>
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      handleSave('company')
                    }}
                  >
                    <Typography variant='h6' style={{ marginBottom: '0.5rem' }}>
                      Company
                      <Button
                        variant='outlined'
                        color='primary'
                        style={{ float: 'right' }}
                        onClick={() => toggleAll('company')}
                      >
                        Toggle All
                      </Button>
                    </Typography>
                    <Grid
                      container
                      direction='row'
                      alignItems='stretch'
                      spacing={1}
                    >
                      {state.selectedRoleActions
                        .filter(a => a.type === 'company')
                        .map(a => {
                          return (
                            <Grid key={a.id} item xs={12} sm={6} lg={4}>
                              <FormGroup>
                                <FormControlLabel
                                  style={{ margin: 0 }}
                                  control={
                                    <Checkbox
                                      color='primary'
                                      size='small'
                                      checked={a.active === 1 ? true : false}
                                      onChange={() => handleChange(a.id)}
                                    />
                                  }
                                  label={`${a.value} - ${a.label}`}
                                />
                              </FormGroup>
                            </Grid>
                          )
                        })}
                    </Grid>
                    <div style={{ marginTop: '1rem' }}>
                      <LoadingButton
                        label='Save'
                        isLoading={state.isSaving}
                        color='primaryVLButton'
                        buttonType='submit'
                      />
                    </div>
                  </form>
                </CardContent>
              </Card>

              <Card style={{ width: '100%', margin: '1rem auto' }}>
                <CardContent>
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      handleSave('page')
                    }}
                  >
                    <Typography variant='h6' style={{ marginBottom: '0.5rem' }}>
                      Pages
                      <Button
                        variant='outlined'
                        color='primary'
                        style={{ float: 'right' }}
                        onClick={() => toggleAll('page')}
                      >
                        Toggle All
                      </Button>
                    </Typography>
                    <Grid
                      container
                      direction='row'
                      alignItems='stretch'
                      spacing={1}
                    >
                      {state.selectedRoleActions
                        .filter(a => a.type === 'page')
                        .map(a => {
                          return (
                            <Grid key={a.id} item xs={12} sm={6} lg={4}>
                              <FormGroup>
                                <FormControlLabel
                                  style={{ margin: 0 }}
                                  control={
                                    <Checkbox
                                      color='primary'
                                      size='small'
                                      checked={a.active === 1 ? true : false}
                                      onChange={() => handleChange(a.id)}
                                    />
                                  }
                                  label={`${a.filter1}`}
                                />
                              </FormGroup>
                            </Grid>
                          )
                        })}
                    </Grid>
                    <div style={{ marginTop: '1rem' }}>
                      <LoadingButton
                        label='Save'
                        isLoading={state.isSaving}
                        color='primaryVLButton'
                        buttonType='submit'
                      />
                    </div>
                  </form>
                </CardContent>
              </Card>

              <Card style={{ width: '100%', margin: '1rem auto' }}>
                <CardContent>
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      handleSave('process-level')
                    }}
                  >
                    <Typography variant='h6' style={{ marginBottom: '0.5rem' }}>
                      Process Level
                      <Button
                        variant='outlined'
                        color='primary'
                        style={{ float: 'right' }}
                        onClick={() => toggleAll('process-level')}
                      >
                        Toggle All
                      </Button>
                    </Typography>

                    {Object.keys(processComps).map(key => {
                      return (
                        <div style={{ margin: '0.5rem 0' }}>
                          <Typography variant='h6'>{`Company ${key}`}</Typography>
                          <Grid
                            container
                            direction='row'
                            alignItems='stretch'
                            spacing={1}
                          >
                            {processComps[key].map(a => {
                              return (
                                <Grid key={a.id} item xs={12} sm={6} lg={4}>
                                  <FormGroup>
                                    <FormControlLabel
                                      style={{ margin: 0 }}
                                      control={
                                        <Checkbox
                                          color='primary'
                                          size='small'
                                          checked={
                                            a.active === 1 ? true : false
                                          }
                                          onChange={() => handleChange(a.id)}
                                        />
                                      }
                                      label={`${a.process_level_description} (${a.filter2})`}
                                    />
                                  </FormGroup>
                                </Grid>
                              )
                            })}
                          </Grid>
                        </div>
                      )
                    })}
                    {state.selectedRoleActions
                      .filter(a => a.type === 'process-level')
                      .map(a => {
                        return (
                          <Grid key={a.id} item xs={12} sm={6} lg={4}>
                            <FormGroup>
                              <FormControlLabel
                                style={{ margin: 0 }}
                                control={
                                  <Checkbox
                                    color='primary'
                                    size='small'
                                    checked={a.active === 1 ? true : false}
                                    onChange={() => handleChange(a.id)}
                                  />
                                }
                                label={<div>
                                  <Typography variant="p">{`Company: ${a.secondary_value}`}</Typography>
                                  <Typography variant="subtitle2">{`${a.process_level_description} (${a.value})`}</Typography>
                                </div>
                                }
                              />
                            </FormGroup>
                          </Grid>
                        )
                      })}
                    <div style={{ marginTop: '1rem' }}>
                      <LoadingButton
                        label='Save'
                        isLoading={state.isSaving}
                        color='primaryVLButton'
                        buttonType='submit'
                      />
                    </div>
                  </form>
                </CardContent>
              </Card>
            </>
          )}
        </div>
      )} */}

      {tabVal === 0 && <ClientValues {...props} />}
      {tabVal === 2 && <Users {...props} />}
    </div>
  )
}

function ClientValues (props) {
  const { credentials } = props.fetchInitialData

  const [state, setstate] = useState({
    clientValues: [],
    isLoading: false,
    isFetching: true
  })
  const [currentValue, setCurrentValue] = useState(null)
  const [newEntry, setNewEntry] = useState(false)
  useEffect(() => {
    async function fetchMyAPI () {
      fetchData()
    }
    fetchMyAPI()
  }, [])

  const fetchData = async () => {
    try {
      const resp = await LambdaFetch(
        'admin',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-client-values'
        }),
        '',
        credentials
      )
      setstate({
        ...state,
        clientValues: resp.data.clientValues,
        isLoading: false,
        isFetching: false
      })

      setCurrentValue(null)
      setNewEntry(false)
    } catch (e) {
      console.log(e)
    }
  }
  const handleSubmit = async e => {
    e.preventDefault()
    const curVal = {
      ...currentValue,
      R_KEY: e.target.R_KEY.value,
      R_VALUE: e.target.R_VALUE.value,
      R_DESCRIPTION: e.target.R_DESCRIPTION.value,
      R_ACTIVE: e.target.R_ACTIVE.checked
    }
    setstate({ ...state, isLoading: true })

    await LambdaFetch(
      'admin',
      'post',
      credentials.user.accessToken,
      JSON.stringify({
        action: 'update-client-values',
        clientValue: curVal
      }),
      '',
      credentials
    )

    await fetchData()
  }
  const handleNewSubmit = async e => {
    e.preventDefault()
    setstate({ ...state, isLoading: true })
    const newVal = {
      R_KEY: e.target.R_KEY.value,
      R_VALUE: e.target.R_VALUE.value,
      R_DESCRIPTION: e.target.R_DESCRIPTION.value,
      R_ACTIVE: e.target.R_ACTIVE.checked
    }
    await LambdaFetch(
      'admin',
      'post',
      credentials.user.accessToken,
      JSON.stringify({
        action: 'create-client-values',
        clientValue: newVal
      }),
      '',
      credentials
    )

    await fetchData()
  }
  const confirmDelete = async info => {
    const deleteIndeices = info.data.reduce((acc, cur) => {
      return [...acc, cur.dataIndex]
    }, [])

    const deleteValues = state.clientValues
      .slice()
      .filter((row, index) => {
        return deleteIndeices.includes(index)
      })
      .map(row => row.OBJ_ID)

    await LambdaFetch(
      'admin',
      'post',
      credentials.user.accessToken,
      JSON.stringify({
        action: 'delete-client-values',
        deleteIds: deleteValues
      }),
      'Successfully deleted value(s)',
      credentials
    )
    await fetchData()
  }
  const tableData = state.clientValues.map(row => {
    return {
      ...row,
      edit: (
        <div
          className='editLink'
          onClick={() => {
            setCurrentValue(row)
          }}
        >
          edit
        </div>
      )
    }
  })

  return (
    <div style={{ margin: '1rem' }}>
      {!!currentValue && (
        <Dialog open={!!currentValue}>
          <DialogTitle id='alert-dialog-title'>Edit</DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid
                container
                direction='row'
                alignItems='flex-start'
                spacing={2}
              >
                <Grid item>
                  <TextField
                    id='R_KEY'
                    label='Key'
                    defaultValue={currentValue.R_KEY}
                    autoComplete='off'
                  />
                </Grid>
                <Grid item>
                  <TextField
                    id='R_VALUE'
                    label='Value'
                    defaultValue={currentValue.R_VALUE}
                    autoComplete='off'
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id='R_ACTIVE'
                        defaultChecked={!!currentValue.R_ACTIVE}
                        name='active'
                        color='primary'
                      />
                    }
                    label='Active'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id='R_DESCRIPTION'
                    label='Description'
                    defaultValue={currentValue.R_DESCRIPTION}
                    fullWidth
                    autoComplete='off'
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setCurrentValue(null)}>Cancel</Button>

              <LoadingButton
                label='Edit'
                isLoading={state.isLoading}
                color='primaryVLButton'
                buttonType='submit'
              />
            </DialogActions>
          </form>
        </Dialog>
      )}

      {!!newEntry && (
        <Dialog open={!!newEntry}>
          <DialogTitle id='alert-dialog-title'>Create Value</DialogTitle>
          <form onSubmit={handleNewSubmit}>
            <DialogContent>
              <Grid
                container
                direction='row'
                alignItems='flex-start'
                spacing={2}
              >
                <Grid item>
                  <TextField id='R_KEY' label='Key' autoComplete='off' />
                </Grid>
                <Grid item>
                  <TextField id='R_VALUE' label='Value' autoComplete='off' />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id='R_ACTIVE'
                        name='active'
                        color='primary'
                        defaultChecked
                      />
                    }
                    label='Active'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id='R_DESCRIPTION'
                    label='Description'
                    fullWidth
                    autoComplete='off'
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setNewEntry(false)}>Cancel</Button>

              <LoadingButton
                label='Create'
                isLoading={state.isLoading}
                color='primaryVLButton'
                buttonType='submit'
              />
            </DialogActions>
          </form>
        </Dialog>
      )}
      <ReactTable
        isLoading={state.isFetching}
        cols={clientReactValueCols}
        data={tableData}
      />
    </div>
  )
}

const clientReactValueCols = [
  {
    accessorKey: 'R_KEY',
    header: 'Key',
    filterFn: 'contains'
  },
  {
    accessorKey: 'R_VALUE',
    header: 'Value',
    filterFn: 'contains'
  },
  {
    accessorKey: 'R_DESCRIPTION',
    header: 'Desc',
    filterFn: 'contains'
  },
  {
    accessorKey: 'R_ACTIVE',
    header: 'Active',
    filterFn: 'contains'
  }
]
