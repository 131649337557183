import React from 'react'
import MaterialReactTable from 'material-react-table';
import { Box, IconButton } from '@mui/material'
import { ExportToCsv } from 'export-to-csv' //or use your library of choice here
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'

const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
  };
  
  const csvExporter = new ExportToCsv(csvOptions);

export default function ReactTable (props) {

    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
      };
    
      
      const data = props.data ? (props.data.map(row => {
        Object.keys(row).forEach(function(key,index) { 
            if(row[key] === null){
                row[key] = ''
            }
        });
        return row
      })):([])

  return (
    <MaterialReactTable
      columns={props.cols}
      data={data}
      initialState={{ showColumnFilters: false, density: 'compact' }}
      muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
      enableStickyHeader
      showSkeletons={true}
      enableFilterRankedResults={false}
      enableGlobalFilter={false} //turn off a feature
      renderTopToolbarCustomActions={({ table }) => (
        <Box
          sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
        >
          <IconButton
            onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
            startIcon={<CloudDownloadIcon />}
          >
            <CloudDownloadIcon />
          </IconButton>
        </Box>
      )}
    />
  )
}
