import React, { useState } from 'react';
import TableMui from './TableMui';
import moment from 'moment-timezone';
import getTableOptions from '../constants/TableOptions';
import DialogTable from './DialogTable';
import { dependentCols, dependentBenefitCols } from '../constants/EmployeeCols';
import {dateFormat} from '../utils/formatter';

export default function Dependents(props) {
	const [state, setState] = useState({
		open: false,
		dependent: null,
		title: '',
	});

	const handleClose = () => {
		setState({ ...state, open: false, dependent: null, title: '' });
	};
	if (!props.data) return null;

	const options = getTableOptions({cols: dependentCols, fileName: props.fileName});

	const tableData = props.data.map((d) => {
		return [
			d.rel_code,
			d.last_name,
			d.first_name,
			d.fica_nbr,
			dateFormat(new Date(d.birthdate)),
			d.sex,
			d.dep_type,
			d.active_flag,
			dateFormat(new Date(d.death_date)),
			<div
				className="editLink"
				onClick={() =>
					setState({ ...state, open: true, dependent: d.seq_nbr, title: `${d.first_name} ${d.last_name}` })
				}
			>
				view
			</div>,
		];
	});

	const dependentsBenefitTableData = props.dependentBenefitsData
		.filter((plan) => plan.DEPENDENT === state.dependent).sort((a,b) => new Date(b.START_DATE) - new Date(a.START_DATE))
		.map((plan) => {
			return [
				plan.COVER_TYPE,
				plan.DISPLAY_DESC,
				dateFormat(new Date(plan.EMP_START)),
				plan.PLAN_CODE,
				plan.PLAN_TYPE,
				dateFormat(new Date(plan.START_DATE)),
				dateFormat(new Date(plan.STOP_DATE)),
			];
		});
	return (
		<div>
			<DialogTable
				isOpen={!!state.open}
				tableTitle={state.title}
				handleClose={handleClose}
				tableData={dependentsBenefitTableData}
				cols={dependentBenefitCols}
				fileName={`${props.fileName}_${state.title.replaceAll(' ','_')}`}
			/>
			<TableMui cols={dependentCols} data={tableData} options={options} title="" />
		</div>
	);
}
