import React from 'react';
import TableMui from './TableMui';
import getTableOptions from '../constants/TableOptions';
import { userFieldCols } from '../constants/EmployeeCols';

export default function UserFields(props) {
  if (!props.data) return null;

  const options = getTableOptions({cols: userFieldCols, fileName: props.fileName});

  const tableData = props.data.map((row) => {
    return [
      row.TYPE,
      row.FIELD_NAME,
      row.VALUE,
      row.DESCRIPTION
    ];
  });
  return (
    <TableMui
      cols={userFieldCols}
      data={tableData}
      options={options}
      title=""
    />
  );
}
