import React from 'react';
import NumberFormat from 'react-number-format';
import { dateOptions } from '../utils/CustomTableDateFilter';

const numberRender  = (value, tableMeta, updateValue) => {
  return (
    <NumberFormat
      value={parseFloat(value).toFixed(2)}
      thousandSeparator={true}
      prefix={'$'}
      displayType="text"

    />
  );
}

export const perActHistCols = [
  {
    name: 'actionCode',
    label: 'Action Code',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'effectDate',
    label: 'Effect Date',
    options: {
      filter: true,
      sort: true,
      ...dateOptions({displayName: "Effect Date"}),
    },
  },
  {
    name: 'actionNbr',
    label: 'Action Number',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'reason',
    label: 'Reason',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'reason2',
    label: 'Reason 2',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'userId',
    label: 'User ID',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'actionType',
    label: 'Action Type',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'createUser',
    label: 'Create User',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'dateStamp',
    label: 'Date Stamp',
    options: {
      filter: true,
      sort: true,
      ...dateOptions({displayName: "Stamp Date"}),
    },
  },
  {
    name: 'view',
    label: 'View',
    options: {
      download: false,
      filter: false,
      sort: false,
    },
  },
];

export const perActHistModalCols = [
  {
    name: 'itemName',
    label: 'Item Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'value',
    label: 'Value',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'begDate',
    label: 'Begin Date',
    options: {
      filter: true,
      sort: true,
      ...dateOptions({displayName: "Begin Date"}),
    },
  },
  {
    name: 'user',
    label: 'User',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'date',
    label: 'Date',
    options: {
      filter: true,
      sort: true,
      ...dateOptions({displayName: "Date"}),
    },
  },
  {
    name: 'recordType',
    label: 'Record Type',
    options: {
      filter: true,
      sort: true,
    },
  },
];

export const dedCols = [
  {
    name: 'ded',
    label: 'Ded',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'amount',
    label: 'Amount or Percent',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'type',
    label: 'Type',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'balanceAmount',
    label: 'Balance Amount',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'one',
    label: '1',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'two',
    label: '2',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'three',
    label: '3',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'four',
    label: '4',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'five',
    label: '5',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'six',
    label: '6',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'seven',
    label: '7',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'eight',
    label: '8',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'nine',
    label: '9',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'beginDate',
    label: 'Begin Date',
    options: {
      filter: true,
      sort: true,
      ...dateOptions({displayName: "Begin Date"}),
    },
  },
  {
    name: 'endDate',
    label: 'End Date',
    options: {
      filter: true,
      sort: true,
      ...dateOptions({displayName: "End Date"}),
    },
  },
  {
    name: 'pty',
    label: 'Pty',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'ar',
    label: 'Ar',
    options: {
      filter: true,
      sort: true,
    },
  },
];

export const payRateHistCols = [
  {
    name: 'begDate',
    label: 'Begin Date',
    options: {
      filter: true,
      sort: true,
      ...dateOptions({displayName: "Begin Date"}),
    },
  },
  {
    name: 'payRate',
    label: 'Pay Rate',
    options: {
      customBodyRender: numberRender,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'salary',
    label: 'Salary',
    options: {
      customBodyRender: numberRender,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'date',
    label: 'Date',
    options: {
      filter: true,
      sort: true,
      ...dateOptions({displayName: "Date"}),
    },
  },
  {
    name: 'sClass',
    label: 'Salary Class',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'posLevel',
    label: 'Position Level',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'hours',
    label: 'Annual Hours',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'fte',
    label: 'NBR FTE',
    options: {
      filter: true,
      sort: true,
    },
  },
];

export const wageCols = [
  {
    name: 'paySumGroup',
    label: 'Pay Sum Group',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'description',
    label: 'Description',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'hours',
    label: 'Hours',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'sumUnits',
    label: 'Units',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'wageSum',
    label: 'Wages',
    options: {
      customBodyRender: numberRender,
      filter: true,
      sort: true,
    },
  },
];

export const deductCols = [
  {
    name: 'dedCode',
    label: 'Deduction',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'description',
    label: 'Description',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'type',
    label: 'Type',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'dedSum',
    label: 'Amount',
    options: {
      customBodyRender: numberRender,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'wageSum',
    label: 'Taxable Wages',
    options: {
      customBodyRender: numberRender,
      filter: true,
      sort: true,
    },
  },
];

export const checkCols = [
  {
    name: 'checkId',
    label: 'Check Id',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <div className="editLink">
            {value}
          </div>
        );
      },
      filter: true,
      sort: true,
    },
  },
  {
    name: 'checkNbr',
    label: 'Check Number',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'checkDate',
    label: 'Check Date',
    options: {
      filter: true,
      sort: true,
      ...dateOptions({displayName: "Check Date"}),
    },
  },
  {
    name: 'printType',
    label: 'Print Type',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'checkType',
    label: 'Check Type',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'grossPay',
    label: 'Gros Pay',
    options: {
      customBodyRender: numberRender,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'netPayAmt',
    label: 'Net Pay Amount',
    options: {
      customBodyRender: numberRender,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'perEndDate',
    label: 'Period End Date',
    options: {
      filter: true,
      sort: true,
      ...dateOptions({displayName: "Period End Date"}),
    },
  },
  {
    name: 'mCheckType',
    label: 'M Check Type',
    options: {
      filter: true,
      sort: true,
    },
  },
]

export const hrHistCols = [
  {
    name: 'fldNbr',
    label: 'Folder Number',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'begDate',
    label: 'Begin Date',
    options: {
      filter: true,
      sort: true,
      ...dateOptions({displayName: "Begin Date"}),
    },
  },
  {
    name: 'nValue',
    label: 'N Value',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'aValue',
    label: 'A Value',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'dValue',
    label: 'D Value',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'userId',
    label: 'User ID',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'date',
    label: 'Date',
    options: {
      filter: true,
      sort: true,
      ...dateOptions({displayName: "Date"}),
    },
  },
  {
    name: 'actionCode',
    label: 'Action Code',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'effectDate',
    label: 'Effective Date',
    options: {
      filter: true,
      sort: true,
      ...dateOptions({displayName: "Effective Date"}),
    },
  },
  {
    name: 'reason1',
    label: 'Reason 1',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'reason2',
    label: 'Reason 2',
    options: {
      filter: true,
      sort: true,
    },
  },
   {
    name: 'dateStamp',
    label: 'Date Stamp',
    options: {
      filter: true,
      sort: true,
      ...dateOptions({displayName: "Stamp Date"}),
    },
  },
  {
    name: 'view',
    label: 'View',
    options: {
      download: false,
      filter: true,
      sort: true,
    },
  },
]

export const userFieldCols = [
  {
    name: 'type',
    label: 'Type',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'fieldName',
    label: 'Field Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'value',
    label: 'Value',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'description',
    label: 'Description',
    options: {
      filter: true,
      sort: true,
    },
  },
];

export const groupCols = [
  {
    name: '',
    label: '',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: '',
    label: '',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: '',
    label: '',
    options: {
      customBodyRender: numberRender,
      filter: true,
      sort: true,
    },
  },
];

export const benefitCols = [
  {
    name: 'PLAN_TYPE',
    label: 'Plan Type',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'PLAN_CODE',
    label: 'Plan Code',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'DISPLAY_DESC',
    label: 'Description',
    options: {
      filter: false,
      sort: false,
    },
  },
   {
    name: 'FLEX_PLAN',
    label: 'Flex Plan',
    options: {
      filter: true,
      sort: true,
    },
  },
   {
    name: 'START_DATE',
    label: 'Start Date',
    options: {
      filter: false,
      sort: true,
      ...dateOptions({displayName: "Start Date"}),
    },
  },
   {
    name: 'STOP_DATE',
    label: 'Stop Date',
    options: {
      filter: false,
      sort: true,
      ...dateOptions({displayName: "Stop Date"}),
    },
  },
   {
    name: 'PLAN_OPTION',
    label: 'Plan Option',
    options: {
      filter: true,
      sort: true,
    },
  },
   {
    name: 'COV_OPTION',
    label: 'Cov Option',
    options: {
      filter: true,
      sort: true,
    },
  },

  {
    name: 'COVER_AMT',
    label: 'Cover Amount',
    options: {
      customBodyRender: numberRender,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'DEP_COVER_AMT',
    label: 'Dep Cover Amount',
    options: {
      customBodyRender: numberRender,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'ANNUAL_AMT',
    label: 'Annual Amount',
    options: {
      customBodyRender: numberRender,
      filter: true,
      sort: true,
    },
  },
];


export const dependentCols = [
  {
    name: 'rel_code',
    label: 'Relation Code',
    options: {
      filter: true,
      sort: true,
    },
  },
{
    name: 'last_name',
    label: 'Last Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'first_name',
    label: 'First Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'fica_nbr',
    label: 'Fica',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'birthdate',
    label: 'Birth Date',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'sex',
    label: 'Sex',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'dep_type',
    label: 'Type',
    options: {
      filter: true,
      sort: true,
    },
  },
{
    name: 'active_flag',
    label: 'Active',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'death_date',
    label: 'Death Date',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'view_benefits',
    label: 'Benefits',
    options: {
        download: false,
      filter: false,
      sort: false,
    },
  },
];


export const dependentBenefitCols = [
  {
    name: 'COVER_TYPE',
    label: 'Cover Type',
    options: {
      filter: true,
      sort: true,
    },
  },
{
    name: 'DISPLAY_DESC',
    label: 'Description',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'EMP_START',
    label: 'Employee Start',
    options: {
      filter: false,
      sort: true,
      ...dateOptions({displayName: "Emp Start Date"}),
    },
  },
  {
    name: 'PLAN_CODE',
    label: 'Plan Code',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'PLAN_TYPE',
    label: 'Plan Type',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'START_DATE',
    label: 'Start Date',
    options: {
      filter: false,
      sort: true,
      ...dateOptions({displayName: "Start Date"}),
    },
  },
  {
    name: 'STOP_DATE',
    label: 'Stop Date',
    options: {
      filter: false,
      sort: true,
      ...dateOptions({displayName: "Stop Date"}),
    },
  },
];


