import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Typography,
  Grid,
  MenuItem,
  TextField,
  Paper,
  InputAdornment,
  Button,
  Card,
  CardContent
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LambdaFetch from '../functions/FetchFromLambda'
import LoadingButton from '../components/LoadingButton'
import { customerActivityCols } from '../constants/ArCols'
import TableMui from '../components/TableMui'
import getTableOptions from '../constants/TableOptions'
import DialogTable from '../components/DialogTable'
import LoadingCircle from '../components/common/LoadingCircle'
import { smallScreenWidth } from '../constants/AppConstants'
import { ServerSideTable } from '../components/ServerSideTable'
import ServerSideAutoCompletev2 from '../functions/ServerSideAutoCompletev2'
import TreeView from '@material-ui/lab/TreeView'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TreeItem from '@material-ui/lab/TreeItem'
import Drawer from '../components/common/Drawer'
import AutocompleteDropDown from '../components/AutocompleteDropdown'

const _ = require('lodash')

const serversideEntriesPerPage = 50

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem'
  },
  treeRoot: {
    flexGrow: 1,
    margin: '1rem'
  }
}))

const startAdornment = (
  <>
    <InputAdornment position='start'>
      <div></div>
    </InputAdornment>
  </>
)
const defaultHeaders = {
  company: '',
  customer: '',
  fromDate: '',
  toDate: ''
}

export default function CustomerActivity (props) {
  const [state, setstate] = useState({
    isLoading: false,
    data: null
  })
  const [headers, setHeaders] = useState(defaultHeaders)

  const [clearHeader, setClearHeaders] = React.useState(false)

  const classes = useStyles()

  const { credentials } = props.fetchInitialData

  const handleSubmit = async e => {
    e.preventDefault()
    fetchCutomerActivityData(headers)
  }

  const fetchCutomerActivityData = async params => {
    setstate({ ...state, isLoading: true })

    try {
      const resp = await LambdaFetch(
        'ar-customer-activity',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-customer-activity',
          ...params
        }),
        '',
        credentials
      )

      setstate({
        ...state,
        customerData: resp.data.customerData[0],
        data: resp.data.customerActivity,
        isLoading: false
      })
    } catch (e) {
      console.log(e)
      setstate({ ...state, isLoading: false })
    }
  }

  const options = getTableOptions({
    cols: customerActivityCols,
    fileName: `Cutomer_Activity`
  })

  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size='small'
        onClick={() => props.history.push('/')}
        aria-label='delete'
      >
        <ArrowBackIcon />
        {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
          'Dashboard'}
      </IconButton>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
        AR90 - Customer Activity
      </Typography>
      <div className={classes.root}>
        <Paper elevation={0} style={{ padding: '1rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              alignContent='center'
              alignItems='flex-start'
              spacing={3}
            >
              <Grid item>
              <AutocompleteDropDown
                  {...props}
                  id={'company'}
                  name={'Company'}
                  width='175px'
                  key={clearHeader}
                  required={true}
                  variant='standard'
                  label={'Company'}
                  value={headers.company}
                  category={'company'}
                  screen={'gl-trans'}
                  onChange={val =>
                    setHeaders({
                      ...headers,
                      company: val ? val.key_value : ''
                    })
                  }
                />
              </Grid>
              {[
                // {
                //   id: 'company',
                //   dataField: 'COMPANY',
                //   label: 'Company',
                //   dateType: 'string',
                //   isRequired: true
                // },
                {
                  id: 'customer',
                  label: 'Customer',
                  dataField: 'CUSTOMER',
                  dataType: 'string',
                  isRequired: true
                }
              ].map(field => {
                return (
                  <Grid key={field.id} item>
                    <ServerSideAutoCompletev2
                      disabled={false}
                      required={field.isRequired}
                      id={field.id}
                      width='175px'
                      label={field.label}
                      key={clearHeader}
                      dontAutoHighlight
                      dontOpenOnFocus
                      accessToken={credentials.user.accessToken}
                      error={`No ${field.label} Found`}
                      variant='standard'
                      handleChange={(id, hit) => {
                        setHeaders({ ...headers, [field.id]: id })
                      }}
                      credentials={credentials}
                      apiResource='ar-customer-activity'
                      secondaryParams={{
                        limit: 10,
                        lookupField: field.dataField,
                        action: 'autocomplete-customer-activity',
                        ...headers
                      }}
                    />
                  </Grid>
                )
              })}

              {[
                {
                  id: 'fromDate',
                  label: 'From Date'
                },
                {
                  id: 'toDate',
                  label: 'To Date'
                }
              ].map(field => {
                return (
                  <Grid key={field.id} item>
                    <TextField
                      id={field.id}
                      key={clearHeader}
                      style={{ marginTop: '5px', width: '160px' }}
                      type={'date'}
                      required={false}
                      label={field.label}
                      variant='standard'
                      value={headers.id}
                      fullWidth
                      onChange={e => {
                        setHeaders({
                          ...headers,
                          [field.id]: !!e.target.value ? e.target.value : null
                        })
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <span />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                )
              })}
            </Grid>
            <div style={{ marginTop: '1rem' }}>
              <LoadingButton
                label='Submit'
                disabled={false}
                isLoading={state.isLoading}
                color='primaryVLButton'
                buttonType='submit'
              />
            </div>
          </form>
        </Paper>

        {state.data && (
          <div style={{ marginTop: '1rem ' }}>
            <Card
              style={{ marginTop: '1rem' }}
              elevation={props.inverseElevation ? 0 : 2}
            >
              <CardContent style={{ position: 'relative' }}>
                <div
                  style={{
                    display: 'flex',
                    marginTop: '0.5rem',
                    justifyContent: 'flex-start'
                  }}
                >
                  <div>
                    <Typography variant='h5'>
                      {`${state.customerData.search_name} (${state.customerData.CUSTOMER})`}
                    </Typography>
                    <Typography>
                      {`Company: ${state.customerData.COMPANY}`}
                    </Typography>

                    <Typography variant='subtitle1'>
                      {`Status: ${state.customerData.ACTIVE_STATUS}`}
                    </Typography>
                  </div>
                </div>

                <div style={{ marginTop: '1rem' }}>
                  <TableMui
                    cols={customerActivityCols}
                    data={state.data}
                    options={{ ...options, elevation: 2 }}
                    title=''
                  />
                </div>
              </CardContent>
            </Card>
          </div>
        )}
      </div>
    </div>
  )
}
