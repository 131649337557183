import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Typography,
  Grid,
  MenuItem,
  TextField,
  Paper,
  InputAdornment,
  Button,
  Slide,
  Dialog,
  AppBar,
  DialogContent,
  Toolbar
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LambdaFetch from '../functions/FetchFromLambda'
import LoadingButton from '../components/LoadingButton'
import {
  glTransCols,
  journalControlCols,
  journalAttachCols
} from '../constants/GlCols'
import CloseIcon from '@material-ui/icons/Close'
import LoadingCircle from '../components/common/LoadingCircle'
import { smallScreenWidth } from '../constants/AppConstants'
import { ServerSideTable } from '../components/ServerSideTable'
import ReactTableServerSide from '../components/ReactTableServerSide'
import AutocompleteDropDown from '../components/AutocompleteDropdown'
import ReactTable from '../components/ReactTable'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const _ = require('lodash')

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem'
  },
  appBar: {
    position: 'relative',
    backgroundColor: props => props.primaryColor,
    height: '60px'
  },
  treeRoot: {
    flexGrow: 1,
    margin: '1rem'
  }
}))

const defaultHeaders = {
  fiscalYear: '',
  acctPeriod: '',
  company: ''
}

export default function GlTransServerSideV2 (props) {
  const [state, setstate] = useState({
    options: null,
    isLoading: false,
    data: null,
    fetched: true,
    isServerSide: false
  })
  const [showTable, setShowTable] = useState(false)
  const [headers, setHeaders] = useState(defaultHeaders)
  const [clearHeader, setClearHeaders] = React.useState(false)
  const [attachData, setattachData] = useState({
    isOpen: false,
    isLoading: false,
    data: null
  })

  const classes = useStyles()

  const { credentials } = props.fetchInitialData

  const getAttachment = async objId => {
    setattachData({ ...attachData, isOpen: true })
    const resp = await LambdaFetch(
      'gl-trans',
      'post',
      credentials.user.accessToken,
      JSON.stringify({
        action: 'journal-attach-read',
        name: objId
      }),
      '',
      credentials
    )
    const attachments = resp.data.jouralAttach.map(a => {
      return ({
        ...a,
        file_link:  <div onClick={() => {
        navigator.clipboard.writeText(a.link)
        props.fetchInitialData.createSnack('copied to clipboard','success')
        }}
        className="editLink">{a.link}</div>
      })
    })
    setattachData({ 
        ...attachData, 
        isOpen: true, 
        data: attachments 
    })
  }
  const handleSubmit = e => {
    e.preventDefault()
    if (JSON.stringify(headers) === state.tableKey) return null
    setShowTable(true)
    setstate({ ...state, isLoading: true, tableKey: JSON.stringify(headers) })
  }

  const handleCallback = (values, error) => {
    if (error) {
      setstate({ ...state, isLoading: false })
      setShowTable(false)
      return null
    }
    setstate({ ...state, isLoading: false })
  }

  const tableDataTransformation = rows => {
    if (!rows) return []
    return rows.map(row => {
      return {
        ...row,
        VIEW: row.link ? (
          <div
            className='editLink'
            onClick={() => {
              getAttachment(row.link)
            }}
          >
            view
          </div>
        ) : (
          ''
        )
      }
    })
  }

  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size='small'
        onClick={() => props.history.push('/')}
        aria-label='back'
      >
        <ArrowBackIcon />
        {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
          'Dashboard'}
      </IconButton>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
        Journal Control (GL45)
      </Typography>
      <Dialog
        open={attachData.isOpen}
        TransitionComponent={Transition}
        maxWidth='xl'
        fullWidth
        fullScreen={
          props.fetchInitialData.credentials.appWidth < smallScreenWidth
            ? true
            : false
        }
        scroll='body'
        keepMounted
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={() =>
                setattachData({
                  isOpen: false,
                  isLoading: false,
                  data: null
                })
              }
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {attachData.isOpen && (
          <DialogContent style={{ padding: 0 }}>
            {!!attachData.data ? (
              <ReactTable cols={journalAttachCols} data={attachData.data} />
            ) : (
              <LoadingCircle />
            )}
          </DialogContent>
        )}
      </Dialog>
      <div className={classes.root}>
        <Paper elevation={0} style={{ padding: '1rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              alignContent='center'
              alignItems='flex-start'
              spacing={3}
            >
              <Grid item>
                <AutocompleteDropDown
                  {...props}
                  id={'fiscalYear'}
                  name={'Year'}
                  width='125px'
                  key={clearHeader}
                  required={true}
                  variant='standard'
                  label={'Year'}
                  value={headers.fiscalYear}
                  category={'fiscal-year'}
                  screen={'gl-trans'}
                  onChange={val =>
                    setHeaders({
                      ...headers,
                      fiscalYear: val ? val.key_value : ''
                    })
                  }
                />
              </Grid>
              <Grid item>
                <AutocompleteDropDown
                  {...props}
                  id={'acctPeriod'}
                  name={'acctPeriod'}
                  width='125px'
                  key={clearHeader}
                  required={true}
                  variant='standard'
                  label={'Period'}
                  value={headers.acctPeriod}
                  category={'period'}
                  screen={'gl-trans'}
                  onChange={val =>
                    setHeaders({
                      ...headers,
                      acctPeriod: val ? val.key_value : ''
                    })
                  }
                />
              </Grid>
              <Grid item>
                <AutocompleteDropDown
                  {...props}
                  id={'company'}
                  name={'Company'}
                  width='125px'
                  key={clearHeader}
                  required={true}
                  variant='standard'
                  label={'Company'}
                  value={headers.company}
                  category={'company'}
                  screen={'gl-trans'}
                  onChange={val =>
                    setHeaders({
                      ...headers,
                      company: val ? val.key_value : ''
                    })
                  }
                />
              </Grid>
            </Grid>

            <div style={{ marginTop: '1rem' }}>
              <LoadingButton
                label='Submit'
                disabled={!(headers.fiscalYear && headers.acctPeriod)}
                isLoading={state.isLoading}
                color='primaryVLButton'
                buttonType='submit'
              />
              {!!(
                headers.company ||
                headers.fiscalYear ||
                headers.acctPeriod
              ) && (
                <Button
                  onClick={() => {
                    setClearHeaders(!clearHeader)
                    setHeaders(defaultHeaders)
                  }}
                  style={{ marginLeft: '0.5rem' }}
                >
                  {' '}
                  Clear
                </Button>
              )}
            </div>
          </form>
        </Paper>
        {!!showTable && (
          <div style={{ marginTop: '1rem ' }}>
            <ReactTableServerSide
              {...props}
              key={state.tableKey}
              params={{ ...headers }}
              action='journal-control-read'
              apiResource={'gl-trans'}
              transform={tableDataTransformation}
              tableTitle={''}
              tableColumns={journalControlCols}
              callback={handleCallback}
              noPagination
              downloadAction={'journal-control-download'}
              downloadFileName={'Journal_Control_Download'}
            />
          </div>
        )}
      </div>
    </div>
  )
}
