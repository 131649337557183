import { currencyFormat, dateFormat } from '../utils/formatter'

export const customerActivityCols = [
  {
    name: 'TRANS_TYPE',
    label: 'Trans Type',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'INVOICE',
    label: 'Invoice',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'TRANS_DATE',
    label: 'Trans Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'DUE_DATE',
    label: 'Due Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'TRAN_AMT',
    label: 'Tran Amount',
    options: {
        customBodyRender: currencyFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'open_amt',
    label: 'Open Amount',
    options: {
      customBodyRender: currencyFormat,
      filter: true,
      sort: true
    }
  }
]