import React from 'react'
import moment from 'moment-timezone'
import {dateFormat} from '../utils/formatter';

export const printValue = (
  dataObj,
  lookup,
  description,
  secondary,
  secondType
) => {
  const isDate = description.toLowerCase().indexOf('date') !== -1

  return (
    <div>
      <span style={{ fontSize: '0.8rem', color: '#545454' }}>{`${description}:  `}</span>
      <span style={{ fontSize: '0.85rem' }}>
        {`${
          isDate
            ? dateFormat(dataObj[lookup])
            : dataObj[lookup]
        }${
          secondary && dataObj[secondary]
            ? ` (${
                secondType !== 'date'
                  ? dataObj[secondary]
                  : dateFormat(dataObj[secondary])
              })`
            : ''
        } `}
      </span>
    </div>
  )
}
