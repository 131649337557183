import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Typography,
  Grid,
  Button,
  Paper,
  Card,
  CardContent,
  InputAdornment
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LambdaFetch from '../functions/FetchFromLambda'
import LoadingButton from '../components/LoadingButton'
import LoadingCircle from '../components/common/LoadingCircle'
import SimpleAutoComplete from '../functions/SimpleAutoComplete'
import { smallScreenWidth } from '../constants/AppConstants'
import { currencyTextFormat } from '../utils/formatter'
import ServerSideAutoCompletev2 from '../functions/ServerSideAutoCompletev2'
import AutocompleteDropDown from '../components/AutocompleteDropdown'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem'
  },
  descript: { fontSize: '0.9rem', color: '#545454' },
  descriptVal: { fontSize: '1rem', fontWeight: 600 }
}))

const startAdornment = (
  <>
    <InputAdornment position='start'>
      <div></div>
    </InputAdornment>
  </>
)

const defaultHeaders = {
  fiscalYear: '',
  company: '',
  accountUnit: '',
  account: '',
  subAccount: '',
  period: ''
}

export default function Balances (props) {
  const [state, setstate] = useState({
    isLoading: false,
    data: null,
    headerOptions: [],
    fetched: false
  })
  const [headers, setHeaders] = useState(defaultHeaders)
  const [clearHeader, setClearHeaders] = React.useState(false)

  const classes = useStyles()

  const { credentials } = props.fetchInitialData

  useEffect(() => {
    async function fetchMyAPI () {
      fetchData()
    }
    fetchMyAPI()
  }, [])

  const fetchData = async () => {
    try {
      const resp = await LambdaFetch(
        'budget',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-options'
        }),
        '',
        credentials
      )

      setstate({ ...state, headerOptions: resp.data.options, fetched: true })
    } catch (e) {
      console.log(e)
    }
  }
  const handleSubmit = async e => {
    try {
      e.preventDefault()
      setstate({ ...state, isLoading: true })

      const resp = await LambdaFetch(
        'budget',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-balance',
          ...headers
        }),
        '',
        credentials
      )

      if (resp.data.balance.length < 1) {
        props.createSnack('No Information Found', 'warning', 2000)
      }

      setstate({ ...state, isLoading: false, data: resp.data.balance[0] })
    } catch (e) {
      console.log(e)
    }
  }

  const handleChange = (name, value) => {
    setHeaders({ ...headers, [name]: value })
  }
  const printTotalValue = (dataObj, lookup, description, type) => {
    return (
      <div style={{ borderBottom: 'dotted #BFBFBF 1px' }}>
        <span className={classes.descript}>{`${description}:  `}</span>
        <span className={classes.descriptVal} style={{ float: 'right' }}>
          {`${
            type === 'currency'
              ? currencyTextFormat(dataObj[lookup])
              : dataObj[lookup]
          }`}
        </span>
      </div>
    )
  }

  if (!state.fetched) {
    return <LoadingCircle />
  }

  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size='small'
        onClick={() => props.history.push('/')}
        aria-label='delete'
      >
        <ArrowBackIcon />
        {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
          'Dashboard'}
      </IconButton>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
        Balances
      </Typography>

      <div className={classes.root}>
        <Paper elevation={0} style={{ padding: '1rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              alignContent='center'
              alignItems='flex-start'
              spacing={3}
            >
              {/* {[
                {
                  id: 'company',
                  dataField: 'COMPANY',
                  label: 'Company',
                  dateType: 'number',
                  isRequired: true
                },
                {
                  id: 'accountUnit',
                  dataField: 'ACCT_UNIT',
                  label: 'Account Unit',
                  dateType: 'number',
                  isRequired: true
                },
                {
                  id: 'account',
                  dataField: 'ACCOUNT',
                  label: 'Account',
                  dateType: 'number',
                  isRequired: true
                },
                {
                  id: 'subAccount',
                  dataField: 'SUB_ACCOUNT',
                  label: 'Sub Account',
                  dateType: 'number',
                  isRequired: true
                },
                {
                  id: 'fiscalYear',
                  dataField: 'FISCAL_YEAR',
                  label: 'Year',
                  dateType: 'number',
                  isRequired: true
                }
              ].map(field => {
                return (
                  <Grid key={field.id} item>
                    <ServerSideAutoCompletev2
                      disabled={false}
                      required={true}
                      id={field.id}
                      width='150px'
                      label={field.label}
                      key={clearHeader}
                      dontAutoHighlight
                      accessToken={credentials.user.accessToken}
                      error={`No ${field.label} Found`}
                      variant='standard'
                      handleChange={(id, hit) => {
                        handleChange(field.id, id)
                      }}
                      credentials={credentials}
                      apiResource='budget'
                      secondaryParams={{
                        limit: 10,
                        lookupField: field.dataField,
                        action: 'autocomplete-budget',
                        ...headers
                      }}
                    />
                  </Grid>
                )
              })} 
              <Grid item>
                <SimpleAutoComplete
                  id={'period'}
                  name={'Period'}
                  width='125px'
                  key={clearHeader}
                  required={true}
                  variant='standard'
                  label={'Period'}
                  value={headers.period}
                  onChange={val => handleChange('period', val)}
                  options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]}
                  getOptionLabel={val => val.toString()}
                />
              </Grid> */}

<Grid item>
                <AutocompleteDropDown
                  {...props}
                  id={'fiscalYear'}
                  name={'Year'}
                  width='125px'
                  key={clearHeader}
                  required={true}
                  variant='standard'
                  label={'Year'}
                  value={headers.fiscalYear}
                  category={'fiscal-year'}
                  screen={'trial-balances'}
                  onChange={val =>
                    setHeaders({
                      ...headers,
                      fiscalYear: val ? val.key_value : ''
                    })
                  }
                />
              </Grid>
              <Grid item>
                <AutocompleteDropDown
                  {...props}
                  id={'period'}
                  name={'period'}
                  width='125px'
                  key={clearHeader}
                  required={true}
                  variant='standard'
                  label={'Period'}
                  value={headers.period}
                  category={'period'}
                  screen={'trial-balances'}
                  onChange={val =>
                    setHeaders({
                      ...headers,
                      period: val ? val.key_value : ''
                    })
                  }
                />
              </Grid>
             <Grid item>
                <AutocompleteDropDown
                  {...props}
                  id={'company'}
                  name={'company'}
                  width='125px'
                  key={clearHeader}
                  required={true}
                  variant='standard'
                  label={'Company'}
                  value={headers.company}
                  category={'company'}
                  screen={'trial-balances'}
                  onChange={val =>
                    setHeaders({
                      ...headers,
                      company: val ? val.key_value : ''
                    })
                  }
                />
              </Grid>
              {[
                {
                  id: 'accountUnit',
                  label: 'Account Unit',
                  dataField: 'ACCT_UNIT',
                  dataType: '',
                  isRequired: true
                },
                {
                  id: 'account',
                  label: 'Account',
                  dataField: 'ACCOUNT',
                  dataType: 'number',
                  isRequired: true
                },
                {
                  id: 'subAccount',
                  label: 'Sub Account',
                  dataField: 'SUB_ACCOUNT',
                  dataType: 'number',
                  isRequired: true
                }
              ].map(field => {
                if (field.id === 'period') {
                  return (
                    <Grid item>
                      <SimpleAutoComplete
                        id={'period'}
                        name={'Period'}
                        width='125px'
                        key={clearHeader}
                        required={true}
                        variant='standard'
                        label={'Period'}
                        value={headers.period}
                        onChange={val =>
                          setHeaders({ ...headers, period: val })
                        }
                        options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]}
                        getOptionLabel={val => val.toString()}
                      />
                    </Grid>
                  )
                } else {
                  return (
                    <Grid key={field.id} item>
                      <ServerSideAutoCompletev2
                        disabled={false}
                        required={field.isRequired}
                        id={field.id}
                        width='125px'
                        label={field.label}
                        key={clearHeader}
                        dontAutoHighlight
                        dontOpenOnFocus
                        accessToken={credentials.user.accessToken}
                        error={`No ${field.label} Found`}
                        variant='standard'
                        handleChange={(id, hit) => {
                          setHeaders({ ...headers, [field.id]: id })
                        }}
                        credentials={credentials}
                        apiResource='trial-balance'
                        secondaryParams={{
                          limit: 15,
                          lookupField: field.dataField,
                          action: 'autocomplete-trial-balance',
                          ...headers
                        }}
                      />
                    </Grid>
                  )
                }
              })}

            </Grid>
            <div style={{ marginTop: '1rem' }}>
              <LoadingButton
                label='Submit'
                isLoading={state.isLoading}
                color='primaryVLButton'
                buttonType='submit'
              />

              {!!(
                headers.fiscalYear ||
                headers.company ||
                headers.accountUnit ||
                headers.account ||
                headers.subAccount ||
                headers.period
              ) && (
                <Button
                  onClick={() => {
                    setClearHeaders(!clearHeader)
                    setHeaders(defaultHeaders)
                  }}
                  style={{ marginLeft: '0.5rem' }}
                >
                  {' '}
                  Clear
                </Button>
              )}
            </div>
          </form>
        </Paper>

        {state.data && (
          <Grid
            item
            xs={12}
            style={{ position: 'relative', marginTop: '1rem' }}
          >
            <Card
              style={{ marginTop: '1rem' }}
              elevation={props.inverseElevation ? 0 : 2}
            >
              <CardContent style={{ position: 'relative' }}>
                <Paper elevation={0} style={{ width: '350px' }}>
                  {printTotalValue(
                    state.data,
                    'BEGINBAL',
                    'Begin Balance',
                    'currency'
                  )}
                  {printTotalValue(state.data, 'CHG', 'Posted', 'currency')}

                  <div style={{ borderBottom: 'dotted #BFBFBF 1px' }}>
                    <span
                      className={classes.descript}
                    >{`${'Ending Balance'}:  `}</span>
                    <span
                      className={classes.descriptVal}
                      style={{ float: 'right' }}
                    >
                      {`${currencyTextFormat(
                        parseFloat(state.data.BEGINBAL) +
                          parseFloat(state.data.CHG)
                      )}`}
                    </span>
                  </div>
                </Paper>
              </CardContent>
            </Card>
          </Grid>
        )}
      </div>
    </div>
  )
}
