import React from 'react';
import TableMui from './TableMui';
import moment from 'moment-timezone';
import LambdaFetch from '../functions/FetchFromLambda';
import DialogTable from './DialogTable';
import getTableOptions from '../constants/TableOptions';
import { perActHistCols, perActHistModalCols } from '../constants/EmployeeCols';
import {dateFormat} from '../utils/formatter';

export default function PersonalActHisotry(props) {
  const [objInfo, setObjInfo] = React.useState(null);
  const handleClose = () => {
    setObjInfo(null);
  };
  const getPerAction = async (objId) => {
    const resp = await LambdaFetch(
      'employee-form',
      'post',
      props.accessToken,
      JSON.stringify({
        action: 'per_act',
        objId,
        empId: props.empId,
        company: props.company
      }),
      '',
      props.fetchInitialData.credentials
    );
    if (resp.success) {
      setObjInfo(
        resp.data.personalActDetail.map((item) => {
          return [
            item.ITEM_NAME,
            item.VALUE,
            dateFormat(new Date(item.BEG_DATE)),
            item.USER_ID,
            dateFormat(new Date(item.DATE_STAMP)),
            item.RECORD_TYPE,
          ];
        })
      );
    } else {
      setObjInfo(null);
    }
  };
  if (!props.data) return null;  
  const options = getTableOptions({cols: perActHistCols, fileName: props.fileName});

  const tableData = props.data.map((row) => {
    return [
      row.ACTION_CODE,
      dateFormat(new Date(row.EFFECT_DATE)),
      row.ACTION_NBR,
      row.REASON_01,
      row.REASON_02,
      row.USER_ID,
      row.ACTION_TYPE,
      row.CREATE_USER,
      dateFormat(new Date(row.DATE_STAMP)),
      <div className="editLink" onClick={() => getPerAction(row.OBJ_ID)}>
        view
      </div>,
    ];
  });
  return (
    <div>
      <DialogTable
        isOpen={!!objInfo}
        handleClose={handleClose}
        tableData={objInfo}
        cols={perActHistModalCols}
      />
      <TableMui
        cols={perActHistCols}
        data={tableData}
        options={options}
        title=""
      />
    </div>
  );
}
