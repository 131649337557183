import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Drawer, Typography, Fab } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import AccountTreeIcon from '@material-ui/icons/AccountTree'

const useStyles = makeStyles({
  list: {
    width: 450
  },
  fullList: {
    width: 'auto'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    position: 'sticky',
    top: '0px',
    backgroundColor: '#FFFFFF',
    zIndex: 999,
    borderBottom: 'solid rgba(0, 0, 0, 0.12) 1px'
  }
})

export default function TemporaryDrawer (props) {
  const classes = useStyles()
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  })

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const list = anchor => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom'
      })}
      role='presentation'
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={toggleDrawer(props.direction, false)}>
          {props.direction === 'left' ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
        <Typography variant='h6'>Accounting Structure</Typography>
      </div>
      {props.children}
    </div>
  )

  return (
    <div>
      {
        <React.Fragment key={props.direction}>
          <Fab
            size='medium'
            onClick={toggleDrawer(props.direction, true)}
            color='primary'
            aria-label='add'
            className={classes.margin}
          >
            <AccountTreeIcon />
          </Fab>

          <Drawer anchor={props.direction} open={state[props.direction]}>
            {list(props.direction)}
          </Drawer>
        </React.Fragment>
      }
    </div>
  )
}
