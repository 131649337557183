// import React, { useEffect, useState } from 'react'
// import {
//   IconButton,
//   Typography,
//   Grid,
//   Paper,
//   TextField,
//   InputAdornment
// } from '@material-ui/core'
// import TableMui from '../components/TableMui'
// import ArrowBackIcon from '@material-ui/icons/ArrowBack'
// import LambdaFetch from '../functions/FetchFromLambda'
// import { makeStyles } from '@material-ui/core/styles'
// import moment from 'moment-timezone'
// import {
//   cashBookTransCols,
//   cashBookTransReactTableCols
// } from '../constants/CashCodeCols'
// import getTableOptions from '../constants/TableOptions'

// import { smallScreenWidth } from '../constants/AppConstants'
// import ServerSideAutoCompletev2 from '../functions/ServerSideAutoCompletev2'
// import LoadingButton from '../components/LoadingButton'
// import { SimpleSelect } from '../functions/SimpleSelect'
// import { dateFormat } from '../utils/formatter'
// import ReactTable from '../components/ReactTable'
// import AutocompleteDropDown from '../components/AutocompleteDropdown'

// const useStyles = makeStyles(theme => ({
//   root: {
//     flexGrow: 1,
//     margin: '2rem 1rem'
//   },
//   paper: {
//     padding: theme.spacing(2),
//     color: 'rgba(0,0,0,0.9)',
//     height: '100%'
//   },
//   descript: { fontSize: '0.8rem', color: '#545454' },
//   descriptVal: { fontSize: '0.85rem' }
// }))

// const defaultHeaders = {
//   cashCode: '',
//   bankCode: '',
//   fromDate: '',
//   toDate: ''
// }

// export default function CashBookTrans (props) {
//   const classes = useStyles()
//   const { credentials } = props.fetchInitialData
//   const [state, setstate] = useState({
//     cbTransData: null,
//     selectedAsset: '',
//     selectedCompany: '',
//     assetData: null,
//     isLoading: false
//   })
//   const [headers, setHeaders] = useState(defaultHeaders)
//   const [cashCodeSelected, setCashCodeSelected] = React.useState('')
//   const [bankCodeSelected, setBankCodeSelected] = React.useState('')
//   const [transNbr, setTransNbr] = React.useState('')
//   const [searchType, setSearchType] = React.useState('Payment')
//   const handleSubmit = e => {
//     e.preventDefault()
//     if (!cashCodeSelected || !bankCodeSelected) return null
//     getCbTrans(cashCodeSelected, bankCodeSelected)
//   }
//   const clearHeader = () => {}
//   const handleSubmitTrans = e => {
//     e.preventDefault()

//     if (!transNbr && !(headers.cashCode && headers.bankCode)) {
//       props.fetchInitialData.createSnack(
//         'Please enter a Payment or Cash Code AND Payment Type',
//         'warning',
//         3000
//       )
//       return null
//     }

//     if (!!transNbr) {
//       getCbTransByNbr(transNbr)
//     } else {
//       getCbTrans(
//         headers.cashCode,
//         headers.bankCode,
//         headers.fromDate,
//         headers.toDate
//       )
//     }
//   }

//   const getCbTrans = async (cash, bank, fromDate, toDate) => {
//     try {
//       setstate({ ...state, isLoading: true })
//       const resp = await LambdaFetch(
//         'cash-book',
//         'post',
//         credentials.user.accessToken,
//         JSON.stringify({
//           action: 'get-cashbook',
//           cashCode: cash,
//           bankCode: bank,
//           fromDate,
//           toDate
//         }),
//         '',
//         credentials
//       )
//       setstate({
//         ...state,
//         cbTransData: resp.data.cbTrans,
//         isLoading: false
//       })
//     } catch (e) {
//       console.log(e)
//     }
//   }

//   const getCbTransByNbr = async nbr => {
//     try {
//       setstate({ ...state, isLoading: true })
//       const resp = await LambdaFetch(
//         'cash-book',
//         'post',
//         credentials.user.accessToken,
//         JSON.stringify({
//           action: 'get-cashbook-by-nbr',
//           transNbr: nbr
//         }),
//         '',
//         credentials
//       )
//       setstate({
//         ...state,
//         cbTransData: resp.data.cbTrans,
//         isLoading: false
//       })
//     } catch (e) {
//       console.log(e)
//     }
//   }

//   const printValue = (dataObj, lookup, description, secondary, secondType) => {
//     const isDate = description.toLowerCase().indexOf('date') !== -1

//     return (
//       <div>
//         <span className={classes.descript}>{`${description}:  `}</span>
//         <span className={classes.descriptVal}>
//           {`${isDate ? dateFormat(dataObj[lookup]) : dataObj[lookup]}${
//             secondary && dataObj[secondary]
//               ? ` (${
//                   secondType !== 'date'
//                     ? dataObj[secondary]
//                     : dateFormat(dataObj[secondary])
//                 })`
//               : ''
//           } `}
//         </span>
//       </div>
//     )
//   }
//   const options = getTableOptions({
//     cols: cashBookTransCols,
//     fileName: `Cash_Book_Trans_${cashCodeSelected}_${bankCodeSelected}`
//   })
//   return (
//     <div style={{ padding: '1.5rem 0.5rem' }}>
//       <IconButton
//         style={{ borderRadius: '5px', position: 'absolute' }}
//         size='small'
//         onClick={() => props.history.push('/')}
//         aria-label='delete'
//       >
//         <ArrowBackIcon />
//         {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
//           'Dashboard'}
//       </IconButton>
//       <Typography variant='h5' style={{ textAlign: 'center' }}>
//         Cash Book Trans (CB80)
//       </Typography>

//       <div className={classes.root}>
//         <Paper elevation={0} style={{ padding: '1rem' }}>
//           <form onSubmit={handleSubmitTrans}>
//             <Grid
//               container
//               alignContent='center'
//               alignItems='flex-end'
//               spacing={2}
//             >
//               <Grid item>
//                 <ServerSideAutoCompletev2
//                   disabled={false}
//                   id='transNbr'
//                   width='200px'
//                   label='Payment'
//                   key={searchType}
//                   accessToken={credentials.user.accessToken}
//                   error='No Trans Nbr Found'
//                   variant='standard'
//                   handleChange={(id, hit) => {
//                     setTransNbr(id)
//                   }}
//                   credentials={credentials}
//                   apiResource='cash-book'
//                   secondaryParams={{
//                     limit: 10,
//                     action: 'autocomplete_trans_nbr'
//                   }}
//                 />
//               </Grid>
//               <Grid item>
//                 <Typography style={{ verticalAlign: 'bottom' }} variant='body1'>
//                   OR
//                 </Typography>
//               </Grid>
//               <Grid item>
//                 <AutocompleteDropDown
//                   {...props}
//                   id={'cashCode'}
//                   name={'cashCode'}
//                   width='220px'
//                   key={'cashCode'}
//                   required={false}
//                   variant='standard'
//                   label={'Cash Code'}
//                   value={headers.cashCode}
//                   category={'cash-code'}
//                   screen={'cash-book-trans'}
//                   onChange={val =>
//                     setHeaders({
//                       ...headers,
//                       cashCode: val ? val.key_value : null
//                     })
//                   }
//                 />
//               </Grid>
//               <Grid item>
//                 <AutocompleteDropDown
//                   {...props}
//                   id={'bankInstCode'}
//                   name={'bankInstCode'}
//                   width='220px'
//                   key={'bankInstCode'}
//                   required={false}
//                   variant='standard'
//                   label={'Payment Type'}
//                   value={headers.bankCode}
//                   category={'payment-type'}
//                   screen={'cash-book-trans'}
//                   onChange={val =>
//                     setHeaders({
//                       ...headers,
//                       bankCode: val ? val.key_value : null
//                     })
//                   }
//                 />
//               </Grid>
//               {[
//                 {
//                   id: 'fromDate',
//                   label: 'From Date'
//                 },
//                 {
//                   id: 'toDate',
//                   label: 'To Date'
//                 }
//               ].map(field => {
//                 return (
//                   <Grid key={field.id} item>
//                     <TextField
//                       id={field.id}
//                       key={clearHeader}
//                       style={{ marginBottom: '5px', width: '160px' }}
//                       type={'date'}
//                       required={false}
//                       label={field.label}
//                       variant='standard'
//                       value={headers.id}
//                       fullWidth
//                       onChange={e => {
//                         setHeaders({
//                           ...headers,
//                           [field.id]: !!e.target.value ? e.target.value : null
//                         })
//                       }}
//                       InputProps={{
//                         startAdornment: (
//                           <InputAdornment position='start'>
//                             <span />
//                           </InputAdornment>
//                         )
//                       }}
//                     />
//                   </Grid>
//                 )
//               })}
//             </Grid>

//             <div style={{ marginTop: '1rem' }}>
//               <LoadingButton
//                 label='Submit'
//                 isLoading={state.isLoading}
//                 color='primaryVLButton'
//                 buttonType='submit'
//               />
//             </div>
//           </form>
//         </Paper>
//         {!!state.cbTransData && (
//           <Grid
//             item
//             xs={12}
//             style={{ position: 'relative', marginTop: '1rem' }}
//           >
//             <ReactTable
//               cols={cashBookTransReactTableCols}
//               data={state.cbTransData}
//             />
//           </Grid>
//         )}
//       </div>
//     </div>
//   )
// }

import React, { useState } from 'react'
import {
  IconButton,
  Typography,
  Grid,
  Paper,
  TextField,
  InputAdornment
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { makeStyles } from '@material-ui/core/styles'
import { cashBookTransServerSideCols,cashBookDistribCols } from '../constants/CashCodeCols'
import { smallScreenWidth } from '../constants/AppConstants'
import ServerSideAutoCompletev2 from '../functions/ServerSideAutoCompletev2'
import LoadingButton from '../components/LoadingButton'
import ReactTableServerSide from '../components/ReactTableServerSide'
import AutocompleteDropDown from '../components/AutocompleteDropdown'
import LambdaFetch from '../functions/FetchFromLambda'
import DialogTable from '../components/DialogTable'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem'
  },
  paper: {
    padding: theme.spacing(2),
    color: 'rgba(0,0,0,0.9)',
    height: '100%'
  },
  descript: { fontSize: '0.8rem', color: '#545454' },
  descriptVal: { fontSize: '0.85rem' }
}))

const defaultHeaders = {
  transNbr: '',
  cashCode: '',
  bankCode: '',
  fromDate: '',
  toDate: ''
}

export default function CashBookTrans (props) {
  const classes = useStyles()
  const { credentials } = props.fetchInitialData
  const [state, setstate] = useState({
    cbTransData: null,
    selectedAsset: '',
    selectedCompany: '',
    assetData: null,
    isLoading: false,
    tableKey: JSON.stringify(defaultHeaders)
  })
  const [headers, setHeaders] = useState(defaultHeaders)
  const [transNbr, setTransNbr] = React.useState('')
  const [showTable, setShowTable] = useState(false)
  const [distrib, setDistrib] = useState(null)

  const handleSubmitTrans = e => {
    e.preventDefault()

    if (!headers.transNbr && !(headers.cashCode && headers.bankCode)) {
      props.fetchInitialData.createSnack(
        'Please enter a Payment or Cash Code AND Payment Type',
        'warning',
        3000
      )
      return null
    }
    if (JSON.stringify(headers) === state.tableKey) return null
    setShowTable(true)
    setstate({ ...state, isLoading: true, tableKey: JSON.stringify(headers) })
  }
  const getDistribData = async (code, id) => {
    try {
      const resp = await LambdaFetch(
        'cash-book',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'cb-distrib-read',
          instCode: code,
          transId: id
        }),
        '',
        credentials
      )
    setDistrib(resp.data.cbDistrib)
    } catch (e) {
      console.log(e)
    }
  }
  const tableDataTransformation = (rows) => {
    return rows.map((row) => {
      return {
        ...row,
        VIEW_DISTRIB: (
          <div
            className="editLink"
            onClick={() => {
              getDistribData(row.BANK_INST_CODE, row.TRANS_IDENT);
            }}
          >
            view
          </div>
        )
      };
    });
  };
  const handleCallback = (values, error) => {
    if (error) {
      setstate({ ...state, isLoading: false })
      setShowTable(false)
      return null
    }
    setstate({ ...state, isLoading: false })
  }
  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size='small'
        onClick={() => props.history.push('/')}
        aria-label='delete'
      >
        <ArrowBackIcon />
        {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
          'Dashboard'}
      </IconButton>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
        Cash Book Trans (CB80)
      </Typography>

      <DialogTable
        isOpen={!!distrib}
        handleClose={() => setDistrib(null)}
        tableData={distrib}
        cols={cashBookDistribCols}
        fileName={`distrib`}
        noFooter
      />

      <div className={classes.root}>
        <Paper elevation={0} style={{ padding: '1rem' }}>
          <form onSubmit={handleSubmitTrans}>
            <Grid
              container
              alignContent='center'
              alignItems='flex-end'
              spacing={2}
            >
              <Grid item>
                <ServerSideAutoCompletev2
                  disabled={false}
                  id='transNbr'
                  width='200px'
                  label='Payment'
                  accessToken={credentials.user.accessToken}
                  error='No Trans Nbr Found'
                  variant='standard'
                  handleChange={(id, hit) => {
                    setHeaders({ ...headers, transNbr: id })
                  }}
                  credentials={credentials}
                  apiResource='cash-book'
                  secondaryParams={{
                    limit: 10,
                    action: 'autocomplete_trans_nbr'
                  }}
                />
              </Grid>
              <Grid item>
                <Typography style={{ verticalAlign: 'bottom' }} variant='body1'>
                  OR
                </Typography>
              </Grid>
              <Grid item>
                <AutocompleteDropDown
                  {...props}
                  id={'cashCode'}
                  name={'cashCode'}
                  width='220px'
                  key={'cashCode'}
                  required={false}
                  variant='standard'
                  label={'Cash Code'}
                  value={headers.cashCode}
                  category={'cash-code'}
                  screen={'cash-book-trans'}
                  onChange={val =>
                    setHeaders({
                      ...headers,
                      cashCode: val ? val.key_value : null
                    })
                  }
                />
              </Grid>
              <Grid item>
                <AutocompleteDropDown
                  {...props}
                  id={'bankInstCode'}
                  name={'bankInstCode'}
                  width='220px'
                  key={'bankInstCode'}
                  required={false}
                  variant='standard'
                  label={'Payment Type'}
                  value={headers.bankCode}
                  category={'payment-type'}
                  screen={'cash-book-trans'}
                  onChange={val =>
                    setHeaders({
                      ...headers,
                      bankCode: val ? val.key_value : null
                    })
                  }
                />
              </Grid>
              {[
                {
                  id: 'fromDate',
                  label: 'From Date'
                },
                {
                  id: 'toDate',
                  label: 'To Date'
                }
              ].map(field => {
                return (
                  <Grid key={field.id} item>
                    <TextField
                      id={field.id}
                      style={{ marginBottom: '5px', width: '160px' }}
                      type={'date'}
                      required={false}
                      label={field.label}
                      variant='standard'
                      value={headers.id}
                      fullWidth
                      onChange={e => {
                        setHeaders({
                          ...headers,
                          [field.id]: !!e.target.value ? e.target.value : null
                        })
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <span />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                )
              })}
            </Grid>

            <div style={{ marginTop: '1rem' }}>
              <LoadingButton
                label='Submit'
                isLoading={state.isLoading}
                color='primaryVLButton'
                buttonType='submit'
              />
            </div>
          </form>
        </Paper>

        {!!showTable && (
          <div style={{ marginTop: '1rem ' }}>
            <ReactTableServerSide
              {...props}
              key={state.tableKey}
              params={{ ...headers }}
              action='cashbooktrans-read-serverside'
              apiResource={'cash-book'}
              transform={tableDataTransformation}
              tableTitle={''}
              tableColumns={cashBookTransServerSideCols}
              callback={handleCallback}
              noPagination
              downloadAction={'cashbooktrans-read-serverside-download'}
              downloadFileName={'Cashbook_Trans_Download'}
            />
          </div>
        )}
      </div>
    </div>
  )
}
