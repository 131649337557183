import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Tooltip,
  Slide
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LambdaFetch from '../functions/FetchFromLambda'
import { printManagerCols } from '../constants/PrintManagerCols'
import {Skeleton} from '@material-ui/lab'
import TableMui from '../components/TableMui'
import getTableOptions from '../constants/TableOptions'
import { smallScreenWidth } from '../constants/AppConstants'
import CachedIcon from '@mui/icons-material/Cached'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '1rem'
  }
}))

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function PrintManager (props) {
  const [state, setstate] = useState({
    printManagerData: [],
    downloadId: null,
    isFetching: false
  })
  const [deleteData, setDeleteData] = useState({
    isOpen: false,
    isLoading: false,
    files: []
  })
  const [params, setParams] = useState(null)
  const [paramsHeader, setParamsHeader] = useState(null)
  const classes = useStyles()

  const { credentials } = props.fetchInitialData

  const fetch = async () => {
    try {
      const resp = await LambdaFetch(
        'reports',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'print-manager-read'
        }),
        '',
        credentials
      )
      const printData = resp.data.printManagerData.map(document => {
        return {
          ...document,
          params_link: document.params ? (
            <div
              className='editLink'
              onClick={() => {
                setParams(document.params)
                setParamsHeader(document.event_name)
              }}
            >
              view
            </div>
          ) : (
            ''
          )
        }
      })
      setstate({ ...state, printManagerData: printData,isFetching: false })
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    fetch()
  }, [])

  const getFile = async (key, name, id) => {
    setstate({ ...state, downloadId: id })
    const myKey = `${key}.part_00000`
    try {
      const resp = await LambdaFetch(
        'get-s3-object',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          key: myKey,
          name: name,
          time: 60 * 5
        }),
        '',
        credentials
      )

      if (resp.success) {
        const downloadUrl = resp.data.url
        window.open(downloadUrl, '_blank')
      }
      setstate({ ...state, downloadId: null })
    } catch (e) {
      console.log(e)
      setstate({ ...state, downloadId: null })
    }
  }

  const confirmDelete = async info => {
    const deleteIndeices = info.data.reduce((acc, cur) => {
      return [...acc, cur.dataIndex]
    }, [])

    const deleteFiles = state.printManagerData
      .slice()
      .filter((row, index) => {
        return deleteIndeices.includes(index)
      })
      .map(row =>
        Object.assign(
          {},
          { id: row.id, document_link: row.document_link, name: row.event_name }
        )
      )

    try {
      await LambdaFetch(
        'get-s3-object',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'delete',
          files: deleteFiles
        }),
        'Successfully deleted file(s)',
        credentials
      )
      // setDeleteData({...deleteData, isLoading: false, isOpen: false, files: []})
      fetch()
    } catch (e) {
      console.log(e)
    }

    // setDeleteData({...deleteData, files: deleteFiles, isOpen: true})
  }
  // const deletFiles = async (currentFiles) => {
  //   setDeleteData({...deleteData, isLoading: true})
  //   try {
  //     await LambdaFetch(
  //       'get-s3-object',
  //       'post',
  //       credentials.user.accessToken,
  //       JSON.stringify({
  //         action: 'delete',
  //         files: currentFiles
  //       }),
  //       'Successfully deleted file(s)',
  //       credentials
  //     )
  //     setDeleteData({...deleteData, isLoading: false, isOpen: false, files: []})
  //     fetch()
  //   } catch (e) {
  //   }
  // }
  const Refresh = () => (
    <Tooltip disableFocusListener title='Add User'>
      <IconButton onClick={() => {
        const skelFill = Array(state.printManagerData.length).fill(
          printManagerCols.reduce((acc, el) => {
            acc[el.name] = <Skeleton height={20} />
            return acc
          }))
        setstate({...state, printManagerData: skelFill, isFetching: true})
        fetch()
      }}>
        <CachedIcon />
      </IconButton>
    </Tooltip>
  )

  const options = {
    ...getTableOptions({
      cols: printManagerCols,
      fileName: `Print_Manager`
    }),
    selectableRows: 'multiple',
    onRowsDelete: confirmDelete,
    responsive: 'scroll',
    customToolbar: Refresh,
    filter: false
  }

  const printTableData = state.printManagerData.map(row => {
    row.link =
      row.status === 'pending' ? (
        <div style={{ margin: 'auto' }}>
          pending...
        </div>
      ) : (
        <div>
          {state.downloadId === row.id ? (
            <div style={{ paddingLeft: '1rem' }}>
              <CircularProgress
                className='loading-circle'
                disableShrink
                size={16}
              />
            </div>
          ) : state.isFetching ? (
            ''
          ): (
            <div
              className='editLink'
              onClick={() =>
                getFile(row.document_link, `${row.event_name}`, row.id)
              }
            >
              download
            </div>
          )}
        </div>
      )
    return row
  })

  return (
    <>
      {params && (
        <Dialog
          fullWidth
          scroll={'paper'}
          open={params}
          // onClose={this.handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle>{paramsHeader}</DialogTitle>
          <DialogContent dividers><pre>{JSON.stringify(JSON.parse(params), null, 2)}</pre></DialogContent>
          <DialogActions>
            <Button onClick={() => setParams(null)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
      {/* <Dialog
				open={deleteData.isOpen}
				TransitionComponent={Transition}
				maxWidth="xl"
				scroll="body"
				keepMounted
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
			<DialogTitle id="title">{'Confirm Delete'}</DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-slide-description">
           Are you sure you want to delete the following file(s)?
           </DialogContentText>

           <List dense style={{overflowY: 'scroll', maxHeight: '250px'}}> 
           {deleteData.files.map(f => {
            return <ListItem>
              <ListItemText primary={f.name} />
              </ListItem>
          })}
       
  </List>
        
				</DialogContent>
				<DialogActions>
					<Button style={{marginRight: '0.5rem'}} onClick={() => setDeleteData({...deleteData, isOpen: false})} variant="text">
						Close
					</Button>


        

          <LoadingButton
                label='Confirm'
                isLoading={deleteData.isLoading}
                color='primaryVLButton'
                fn={() => deleteFiles()}
              />
				</DialogActions>
			</Dialog> */}
      <div
        style={{
          margin:
            props.fetchInitialData.credentials.appWidth < smallScreenWidth
              ? '1rem auto'
              : '1rem'
        }}
      >
        <Grid container>
          <Grid item xs={12} style={{ position: 'relative' }}>
            <TableMui
              cols={printManagerCols}
              data={printTableData}
              options={options}
              title=''
            />
          </Grid>
        </Grid>
      </div>
    </>
  )
}
