import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Typography,
  Grid,
  Paper,
  InputAdornment,
  Button,
  TextField
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LambdaFetch from '../functions/FetchFromLambda'
import LoadingButton from '../components/LoadingButton'
import {
  actransCols,
  actransMoreCols,
  actransReactTableCols
} from '../constants/ActivityCols'
import TableMui from '../components/TableMui'
import getTableOptions from '../constants/TableOptions'
import DialogTable from '../components/DialogTable'
import { smallScreenWidth } from '../constants/AppConstants'
import ServerSideAutoCompletev2 from '../functions/ServerSideAutoCompletev2'
import { ServerSideTable } from '../components/ServerSideTable'
import ReactTableServerSide from '../components/ReactTableServerSide'
import AutocompleteDropDown from '../components/AutocompleteDropdown'

const _ = require('lodash')

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem'
  },
  treeRoot: {
    flexGrow: 1,
    margin: '1rem'
  }
}))

const defaultHeaders = {
  fiscalYear: '',
  period: '',
  company: '',
  activity: '',
  activityGrp: '',
  acctCategory: '',
  fromDate: '',
  toDate: ''
}

export default function Actrans (props) {
  const [state, setstate] = useState({
    isLoading: false,
    data: null,
    tableKey: JSON.stringify(defaultHeaders)
  })
  const [showTable, setShowTable] = useState(false)
  const [headers, setHeaders] = useState(defaultHeaders)
  const [clearHeader, setClearHeaders] = React.useState(false)
  const [moreInfo, setMoreInfo] = useState(null)
  const [showHeaders, setShowHeaders] = useState(false)
  const classes = useStyles()

  const { credentials } = props.fetchInitialData

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const id = urlParams.get('id')
    const type = urlParams.get('type')
    if (id && type) {
      getActivity(id, type)
    } else {
      setShowHeaders(true)
    }
  }, [])

  const getActivity = async (id, type) => {
    setstate({ ...state, isLoading: true })
    try {
      const resp = await LambdaFetch(
        'actrans',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'activity-read',
          id: id,
          lookupCol: type
        }),
        '',
        credentials
      )
      const actransData = tableDataTransformation(resp.data.actrans)

      setstate({
        ...state,
        data: actransData,
        isLoading: false
      })
    } catch (e) {
      setstate({ ...state, isLoading: false })
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (JSON.stringify(headers) === state.tableKey) return null
    setShowTable(true)
    setstate({ ...state, isLoading: true, tableKey: JSON.stringify(headers) })
  }

  const fetchTranData = async params => {
    setstate({ ...state, isLoading: true })

    try {
      const resp = await LambdaFetch(
        'actrans',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-actrans',
          ...params
        }),
        '',
        credentials
      )
      const actransData = tableDataTransformation(resp.data.actrans)

      setstate({
        ...state,
        data: actransData,
        isLoading: false
      })
    } catch (e) {
      console.log(e)
      setstate({ ...state, isLoading: false })
    }
  }
  const tableDataTransformation = data => {
    return data.map(row => {
      row['MORE'] = (
        <div className='editLink' onClick={() => setMoreInfo([row])}>
          view
        </div>
      )

      return row
    })
  }
  const handleCallback = (values, error) => {
    if (error) {
      setstate({ ...state, isLoading: false })
      setShowTable(false)
      return null
    }
    console.log('callback')
    setstate({ ...state, isLoading: false })
  }

  const options = getTableOptions({ cols: actransCols, fileName: `actrans` })
  options.downloadOptions.filterOptions.useDisplayedColumnsOnly = false

  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      <DialogTable
        isOpen={!!moreInfo}
        handleClose={() => setMoreInfo(null)}
        tableData={moreInfo}
        cols={actransMoreCols}
        title={`${moreInfo && moreInfo[0].ACTIVITY}`}
        simpleTable
        noFooter
      />

      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size='small'
        onClick={() => props.history.push('/')}
        aria-label='delete'
      >
        <ArrowBackIcon />
        {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
          'Dashboard'}
      </IconButton>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
        ACTRANS by Period
      </Typography>
      <div className={classes.root}>
        {showHeaders && (
          <Paper elevation={0} style={{ padding: '1rem' }}>
            <form onSubmit={handleSubmit}>
              <Grid
                container
                alignContent='center'
                alignItems='flex-start'
                spacing={3}
              >
                <Grid item>
                  <AutocompleteDropDown
                    {...props}
                    id={'fiscalYear'}
                    name={'fiscalYear'}
                    width='125px'
                    key={'fiscalYear'}
                    required={true}
                    variant='standard'
                    label={'Year'}
                    value={headers.fiscalYear}
                    category={'fiscal-year'}
                    screen={'actrans'}
                    onChange={val =>
                      setHeaders({
                        ...headers,
                        fiscalYear: val ? val.key_value : null
                      })
                    }
                  />
                </Grid>
                <Grid item>
                  <AutocompleteDropDown
                    {...props}
                    id={'period'}
                    name={'period'}
                    width='125px'
                    key={'period'}
                    required={true}
                    variant='standard'
                    label={'Period'}
                    value={headers.period}
                    category={'period'}
                    screen={'actrans'}
                    onChange={val =>
                      setHeaders({
                        ...headers,
                        period: val ? val.key_value : null
                      })
                    }
                  />
                </Grid>
                {/* {[
                  {
                    id: 'fiscalYear',
                    label: 'Year',
                    dataField: 'FISCAL_YEAR',
                    dataType: 'date',
                    isRequired: true
                  },
                  {
                    id: 'period',
                    dataField: 'PERIOD',
                    label: 'Period',
                    dateType: 'number',
                    isRequired: true
                  }
                ].map(field => {
                  return (
                    <Grid key={field.id} item>
                      <ServerSideAutoCompletev2
                        disabled={false}
                        required={field.isRequired}
                        id={field.id}
                        width='125px'
                        label={field.label}
                        key={clearHeader}
                        dontAutoHighlight
                        dontOpenOnFocus
                        accessToken={credentials.user.accessToken}
                        error={`No ${field.label} Found`}
                        variant='standard'
                        handleChange={(id, hit) => {
                          setHeaders({ ...headers, [field.id]: id })
                        }}
                        credentials={credentials}
                        apiResource='actrans'
                        secondaryParams={{
                          limit: 10,
                          lookupField: field.dataField,
                          action: 'autocomplete-actrans-years',
                          ...headers
                        }}
                      />
                    </Grid>
                  )
                })} */}
              </Grid>
              <Grid
                container
                alignContent='center'
                alignItems='flex-start'
                spacing={3}
              >
                <Grid item>
                  <AutocompleteDropDown
                    {...props}
                    disabled={!(headers.fiscalYear && headers.period)}
                    id={'company'}
                    name={'company'}
                    width='125px'
                    key={'company'}
                    required={true}
                    variant='standard'
                    label={'Company'}
                    value={headers.period}
                    category={'company'}
                    screen={'actrans'}
                    onChange={val =>
                      setHeaders({
                        ...headers,
                        company: val ? val.key_value : null
                      })
                    }
                  />
                </Grid>

                {[
                  // {
                  //   id: 'company',
                  //   dataField: 'COMPANY',
                  //   label: 'Company',
                  //   dateType: 'number',
                  //   isRequired: true
                  // },
                  {
                    id: 'activity',
                    dataField: 'ACTIVITY',
                    label: 'Activity',
                    dateType: 'string',
                    isRequired: false
                  },
                  {
                    id: 'activityGrp',
                    dataField: 'ACTIVITY_GRP',
                    label: 'Activity Group',
                    dateType: 'string',
                    isRequired: false
                  },
                  {
                    id: 'acctCategory',
                    dataField: 'ACCT_CATEGORY',
                    label: 'Acct Category',
                    dateType: 'string',
                    isRequired: false
                  }
                ].map(field => {
                  return (
                    <Grid key={field.id} item>
                      <ServerSideAutoCompletev2
                        disabled={!(headers.fiscalYear && headers.period)}
                        required={field.isRequired}
                        id={field.id}
                        width='125px'
                        label={field.label}
                        key={clearHeader}
                        dontAutoHighlight
                        dontOpenOnFocus
                        accessToken={credentials.user.accessToken}
                        error={`No ${field.label} Found`}
                        variant='standard'
                        handleChange={(id, hit) => {
                          setHeaders({ ...headers, [field.id]: id })
                        }}
                        credentials={credentials}
                        apiResource='actrans'
                        secondaryParams={{
                          limit: 10,
                          lookupField: field.dataField,
                          action: 'autocomplete-actrans',
                          ...headers
                        }}
                      />
                    </Grid>
                  )
                })}
                {[
                  {
                    id: 'fromDate',
                    label: 'From Date'
                  },
                  {
                    id: 'toDate',
                    label: 'To Date'
                  }
                ].map(field => {
                  return (
                    <Grid key={field.id} item>
                      <TextField
                        id={field.id}
                        key={clearHeader}
                        style={{ marginTop: '5px', width: '160px' }}
                        type={'date'}
                        required={false}
                        label={field.label}
                        variant='standard'
                        value={headers.id}
                        fullWidth
                        onChange={e => {
                          setHeaders({
                            ...headers,
                            [field.id]: !!e.target.value ? e.target.value : null
                          })
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <span />
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                  )
                })}
              </Grid>

              <div style={{ marginTop: '1rem' }}>
                <LoadingButton
                  label='Submit'
                  disabled={!(headers.fiscalYear && headers.acctPeriod)}
                  isLoading={state.isLoading}
                  color='primaryVLButton'
                  buttonType='submit'
                />
                {!!(
                  headers.fiscalYear ||
                  headers.period ||
                  headers.activity ||
                  headers.activityGrp ||
                  headers.acctCategory ||
                  headers.company ||
                  headers.fromDate ||
                  headers.toDate
                ) && (
                  <Button
                    onClick={() => {
                      setClearHeaders(!clearHeader)
                      setHeaders(defaultHeaders)
                    }}
                    style={{ marginLeft: '0.5rem' }}
                  >
                    {' '}
                    Clear
                  </Button>
                )}
              </div>
            </form>
          </Paper>
        )}
        {!!showTable && (
          <div style={{ marginTop: '1rem ' }}>
            <ReactTableServerSide
              {...props}
              key={state.tableKey}
              params={{ ...headers }}
              action='get-actrans-serverside'
              apiResource={'actrans'}
              transform={tableDataTransformation}
              tableTitle={''}
              tableColumns={actransReactTableCols}
              callback={handleCallback}
              noPagination
              noSort
              downloadAction={'get-actrans-serverside-download'}
            />
          </div>
        )}
      </div>
    </div>
  )
}
