import React, { useState, useEffect } from 'react'
import {
  Grid,
  CircularProgress,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import LambdaFetch from '../functions/FetchFromLambda'
import { staticFileCols } from '../constants/StaticFileCols'
import LoadingButton from '../components/LoadingButton'
import TableMui from '../components/TableMui'
import getTableOptions from '../constants/TableOptions'
import { smallScreenWidth } from '../constants/AppConstants'
import { humanFileSize } from '../utils/HumanFileSize'
import { v4 as uuidv4 } from 'uuid'
const Buffer = require('buffer/').Buffer

export default function StaticFiles (props) {
  const [state, setstate] = useState({
    staticFilesData: []
  })
  const [downloadKey, setDownloadKey] = useState(null)
  const [open, setOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [files, setFiles] = useState([])
  const [selectedRoles, setSelectedRoles] = useState([])
  const { credentials } = props.fetchInitialData

  const fetchData = async () => {
    try {
      const resp = await LambdaFetch(
        'static-files',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'read'
        }),
        '',
        credentials
      )

      const files = resp.data.files ? resp.data.files : []

      setstate({ ...state, staticFilesData: files })
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getFile = async (key, name) => {
    setDownloadKey(key)

    try {
      const resp = await LambdaFetch(
        'get-s3-object',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          key: `staticFiles/${key}`,
          name: name,
          time: 60 * 5,
          noContentDisposition: true
        }),
        '',
        credentials
      )

      if (resp.success) {
        const downloadUrl = resp.data.url
        window.open(downloadUrl, '_blank')
      }
      setDownloadKey(null)
    } catch (e) {
      console.log(e)
      setDownloadKey(null)
    }
  }
  const inputFileChange = e => {
    if (e.target.files[0] === undefined) {
      setFiles([])
    } else {
      setFiles([])

      for (var i = 0; i < e.target.files.length; i++) {
        let file = e.target.files[i],
          reader = new FileReader()
        reader.onload = function (r) {
          // var updatedUpload = files.map(file => Object.assign({}, file))
          // updatedUpload.push({
          //   name: file.name,
          //   src: r.target.result,
          //   size: file.size
          // })
          setFiles([
            {
              name: file.name,
              src: r.target.result,
              size: file.size
            }
          ])
        }

        reader.readAsDataURL(file)
      }
    }
  }

  const createStaticFile = async e => {
    e.preventDefault()
    try {
      setLoading(true)
      const curFile = files[0]
      const fielData = curFile.src.split(';')[1].replace('base64,', '')

      const fileData = {
        fileKey: uuidv4(),
        contentType: curFile.name.substr(curFile.name.lastIndexOf('.') + 1),
        fileName: curFile.name,
        fileDescription: e.target.fileDescription.value,
        fileSize: curFile.size,
        fileRoles: selectedRoles
      }

      const results = await uploadFile(
        fileData.fileKey,
        fileData.contentType,
        fielData
      )

      if (results) {
        const resp = await LambdaFetch(
          'static-files',
          'post',
          credentials.user.accessToken,
          JSON.stringify({
            action: 'create-record',
            ...fileData
          }),
          '',
          credentials
        )

        if (resp.success) {
          setLoading(false)
          setOpen(false)
          props.fetchInitialData.createSnack(
            'Successfully uploaded file',
            'success',
            3000
          )
          fetchData()
        }
      } else {
        throw new Error('Failed to upload file')
      }
    } catch (e) {
      props.fetchInitialData.createSnack(e, 'error', 3000)
    }
  }
  const uploadFile = async (key, contentType, data) => {
    try {
      const resp = await LambdaFetch(
        'static-files',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-signed-post-url',
          key,
          contentType
        }),
        '',
        credentials
      )

      if (resp.success) {
        const uploadurl = JSON.parse(resp.data.body).url

        const results = await fetch(uploadurl, {
          method: 'PUT',
          headers: {
            'Content-Type': contentType
          },
          body: Buffer.from(data, 'base64')
        }).then(res => {
          return res.status
        })

        if (results === 200) {
          setFiles([])
          return true
        } else {
          setLoading(false)
          throw new Error()
        }
      }
    } catch (e) {
      console.log(e)
      props.fetchInitialData.createSnack(
        'There was a error uploading file',
        'error',
        3000
      )
    }
  }

  const confirmDelete = async info => {
    const deleteIndeices = info.data.reduce((acc, cur) => {
      return [...acc, cur.dataIndex]
    }, [])

    const deleteFiles = state.staticFilesData
      .slice()
      .filter((row, index) => {
        return deleteIndeices.includes(index)
      })
      .map(row =>
        Object.assign(
          {},
          { fileId: row.file_id, fileKey: `staticFiles/${row.file_key}` }
        )
      )

    try {
      await LambdaFetch(
        'static-files',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'delete',
          files: deleteFiles
        }),
        null,
        credentials
      )

      props.fetchInitialData.createSnack(
        'Successfully deleted file(s)',
        'success',
        3000
      )

      fetchData()
    } catch (e) {
      console.log(e)
    }
  }

  const fileData = state.staticFilesData.map(cur => {
    const link =
      downloadKey === cur.Key ? (
        <div style={{ paddingLeft: '1rem' }}>
          <CircularProgress
            className='loading-circle'
            disableShrink
            size={16}
          />
        </div>
      ) : (
        <div
          className='editLink'
          onClick={() => getFile(cur.file_key, cur.file_name)}
        >
          download
        </div>
      )

    const file = {
      ...cur,
      size: humanFileSize(cur.file_size, true),
      link
    }
    return file
  })

  const userRoles = props.fetchInitialData.credentials.user.roles.split(',')

  const options = getTableOptions({
    cols: staticFileCols,
    fileName: `Static_Files`
  })
  options['selectableRows'] = 'multiple'
  options['onRowsDelete'] = confirmDelete

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <form onSubmit={createStaticFile}>
          <DialogTitle id='alert-dialog-title'>File Upload</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                style={{ position: 'relative', marginBottom: '0.5rem' }}
              >
                <input type='file' size="true" onChange={inputFileChange} />
              </Grid>

              <Grid item xs={12} style={{ position: 'relative' }}>
                <TextField
                  id='fileDescription'
                  variant='outlined'
                  label='Description'
                  fullWidth
                  size='small'
                />
              </Grid>

              <Grid item xs={12} style={{ position: 'relative' }}>
                <Autocomplete
                  multiple
                  id='fileRoles'
                  options={[...new Set(userRoles.map(r => r))]}
                  getOptionLabel={option => `${option}`}
                  filterSelectedOptions
                  value={selectedRoles}
                  onChange={(e, val) => {
                    setSelectedRoles([...val])
                  }}
                  disableCloseOnSelect
                  renderInput={params => (
                    <TextField
                      {...params}
                      required={selectedRoles.length <= 0}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                      label='Accessible Roles'
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Close</Button>
            {files.length > 0 && (
              <LoadingButton
                color='primary'
                isLoading={isLoading}
                label={'UPLOAD'}
                disabled={files.length === 0}
                buttonType='submit'
              />
            )}
          </DialogActions>
        </form>
      </Dialog>
      <div
        style={{
          margin:
            props.fetchInitialData.credentials.appWidth < smallScreenWidth
              ? '1rem auto'
              : '1rem'
        }}
      >
        <Grid container>
          <Grid item xs={12} style={{ position: 'relative' }}>
            <Button
              style={{ marginBottom: '1rem' }}
              variant='contained'
              component='label'
              color='primary'
              onClick={() => setOpen(true)}
            >
              Upload File
            </Button>
          </Grid>

          <Grid item xs={12} style={{ position: 'relative' }}>
            <TableMui
              cols={staticFileCols}
              data={fileData}
              options={options}
              title=''
            />
          </Grid>
        </Grid>
      </div>
    </>
  )
}