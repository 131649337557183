import React from 'react';
import TableMui from './TableMui';
import moment from 'moment-timezone';
import getTableOptions from '../constants/TableOptions';
import { benefitCols } from '../constants/EmployeeCols';
import {dateFormat} from '../utils/formatter';

export default function Benefits(props) {
	if (!props.data) return null;

	const options = getTableOptions({cols: benefitCols, fileName: props.fileName});

	const tableData = props.data.map((b) => {
		return [
			b.PLAN_TYPE,
			b.PLAN_CODE,
			b.DISPLAY_DESC,
			b.FLEX_PLAN,
			dateFormat(new Date(b.START_DATE)),
			dateFormat(new Date(b.STOP_DATE)),
			b.PLAN_OPTION,
			b.COV_OPTION,
			b.COVER_AMT,
			b.DEP_COVER_AMT,
			b.ANNUAL_AMT,
		];
	});
	return <TableMui cols={benefitCols} data={tableData} options={options} title="" />;
}
